import { CONSTANTS } from './Constants'
import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import authentication from '@feathersjs/authentication-client'
import axios from 'axios'

const feathersClient = feathers()

const restClient = rest(CONSTANTS.API_URL)

feathersClient
  .configure(restClient.axios(axios))
  .configure(authentication({ storage: localStorage }))

// const restClient = feathers.rest(CONSTANTS.API_URL)
// feathersClient.configure(restClient.axios(axios))
// feathersClient.configure(feathers.authentication({storage: window.localStorage}))

export default feathersClient
