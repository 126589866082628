import './App.css'
import React, { Component } from 'react'
import Header from './components/Header'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SecureRoute, { Roles } from './components/SecureRoute'
import RedirectContainer from './containers/RedirectorContainer'
import LoadingContainer from './containers/LoadingContainer'
import ReactLoggly from 'react-loggly'
import feathersClient from './helpers/FeathersClient'
/* Pages */
import TemplatePage from './pages/TemplatePage'
import TemplatesPage from './pages/TemplatesPage'
import WelcomePage from './pages/WelcomePage'
import LoginPage from './pages/LoginPage'
import NoMatchPage from './pages/NoMatchPage'
import WritePage from './pages/WritePage'
import SearchPage from './pages/SearchPage'
import MyLettersPage from './pages/MyLettersPage'
import QuizPage from './pages/QuizPage'
import AdminPage from './pages/AdminPage'
import SectionsPage from './pages/SectionsPage'
import SectionPage from './pages/SectionPage'
import ArticlePage from './pages/ArticlePage'
import ArticlesPage from './pages/ArticlesPage'
import HouseHuntingPage from './pages/HouseHuntingPage'
/* Material-UI */
import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles'
import { isWidthUp } from '@material-ui/core/withWidth'
import { compose } from 'redux'
import { connect } from 'react-redux'
import NotificationContainer from './containers/NotificationContainer'
import { Grid, withWidth } from '@material-ui/core'
import Sidebar from './components/Sidebar'
import ErrorBoundary from './components/ErrorBoundary'
import { CONSTANTS, ENVIRONMENTS } from './helpers/Constants'
import ComingSoonPage from './pages/ComingSoonPage'
import { setUserLocation } from './actions/global'
import { loadLocation } from './helpers/LocationFunctions'
import { signInFromToken } from './actions/user'

const theme = createTheme({
  palette: {
    primary: {
      light: '#819ca9',
      main: '#546e7a',
      dark: '#29434e',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffa4a2',
      main: '#e57373',
      dark: '#af4448',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontFamily: 'Roboto Condensed',
        textTransform: 'uppercase'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 'bold',
        fontSize: '1.4em !important'
      }
    }
  }
})

class App extends Component {
  componentDidMount () {
    const { setUserLocation, userLocation } = this.props

    try {
      feathersClient.reAuthenticate()
        .then(() => {
          this.props.signInFromToken()
        })
        .catch(() => {})
    } catch (error) {
    }
    loadLocation(setUserLocation, userLocation)

    // // Load Amazon Advertisment
    // window.amzn_assoc_ad_type = 'banner'
    // window.amzn_assoc_marketplace = 'amazon'
    // window.amzn_assoc_region = 'US'
    // window.amzn_assoc_placement = 'assoc_banner_placement_default'
    // window.amzn_assoc_campaigns = 'homegarden'
    // window.amzn_assoc_banner_type = 'category'
    // window.amzn_assoc_p = '48'
    // window.amzn_assoc_isresponsive = 'false'
    // window.amzn_assoc_banner_id = '0VDQ8A4JAV09G23TB8R2'
    // window.amzn_assoc_width = '728'
    // window.amzn_assoc_height = '90'
    // window.amzn_assoc_tracking_id = 'realestatelov-20'
    // window.amzn_assoc_linkid = '4941f30a71341a9e4ab8b40b90996a85'
    //
    // const script = document.createElement('script')
    // script.type = 'text/javascript'
    // script.async = true
    // script.src = '//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1'
    //
    // this.adSpace.appendChild(script)
  }

  /**
   * Render function
   */
  render () {
    const { classes } = this.props
    const isLarge = isWidthUp('md', this.props.width)
    return <MuiThemeProvider theme={theme}>
      <ReactLoggly token='88ab79a1-7bbc-4c5c-a318-79517dfce37a'
                   logFromConsole={CONSTANTS.ENVIRONMENT === ENVIRONMENTS.PRODUCTION}>
        <Router>
          <div>
            <Header />
            <section className={`${classes.app} ${isLarge ? classes.appLarge : ''}`}>
              <Grid container spacing={3}>
                <Grid item xs sm md>
                  <ErrorBoundary>
                    <Switch>
                      {/* User Routes */}
                      <Route path='/' exact component={WelcomePage} />
                      <Route path='/login' component={LoginPage} />
                      <Route path='/quiz' component={QuizPage} />
                      <Route path='/search/:tag?' component={SearchPage} />
                      <Route path='/write/new/:templateId' component={WritePage} />
                      <Route path='/write/:letterId' component={WritePage} />
                      <Route path='/signup' component={ComingSoonPage} />
                      <Route path='/articles' component={ArticlesPage} />
                      <Route path='/article/:title' component={ArticlePage} />
                      <Route path='/househunting' component={HouseHuntingPage} />
                      {/* Logged In Routes */}
                      <SecureRoute path='/myletters' component={MyLettersPage} role={Roles.USER} />
                      {/* Admin Routes */}
                      <SecureRoute path='/admin' exact component={AdminPage} role={Roles.ADMIN} />
                      <SecureRoute path='/admin/templates' component={TemplatesPage} role={Roles.ADMIN} />
                      <SecureRoute path='/admin/template/:id?' component={TemplatePage} role={Roles.ADMIN} />
                      <SecureRoute path='/admin/sections' component={SectionsPage} role={Roles.ADMIN} />
                      <SecureRoute path='/admin/section/:id?' component={SectionPage} role={Roles.ADMIN} />
                      <Route component={NoMatchPage} />
                    </Switch>
                  </ErrorBoundary>
                </Grid>
                {false && !this.props.isSignedIn &&
                <Grid item xs={12} md={2}>
                  <Sidebar />
                </Grid>
                }
              </Grid>
              {/*<div className={classes.adSpace} ref={el => (this.adSpace = el)} />*/}
              <div ref={el => (this.surveySpace = el)} />
            </section>
            <footer className={classes.footer}>
              {/*<iframe*/}
              {/*className={classes.footerAd}*/}
              {/*src='//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=homegarden&banner=0YWXTX9SSC56JE97MSG2&f=ifr&linkID=214d9ff02d725df19ccd127991936c4b&t=realestatelov-20&tracking_id=realestatelov-20'*/}
              {/*scrolling='no'*/}
              {/*title='amazon'*/}
              {/*/>*/}
              <div className={classes.footerContent}>
                Home Love Letter&trade; is a trademark of <a href='//unionjackcreative.com' target='_blank'
                                                             rel='noopener noreferrer'>Union Jack Creative</a>. All
                content &copy; 2018 <a href='mailto:jack@unionjackcreative.com'>Contact Us</a>
              </div>
            </footer>
            <RedirectContainer />
            <LoadingContainer />
            <NotificationContainer />
          </div>
        </Router>
      </ReactLoggly>
    </MuiThemeProvider>
  }
}

const mapStateToProps = state => {
  // TODO: Add ROLE check here
  const { isSignedIn } = state.user
  const { userLocation } = state.global
  return {
    isSignedIn,
    userLocation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signInFromToken: () => dispatch(signInFromToken()),
    setUserLocation: location => { dispatch(setUserLocation(location)) }
  }
}

// Styling for Paper component
const styles = {
  root: {
    padding: 16
  },
  iconButton: {
    width: 30,
    height: 30
  },
  mainGridItem: {
    paddingRight: '0 !important'
  },
  app: {
    margin: '0 auto',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'left',
    minHeight: 'calc(100vh - 100px)', // minus footer height
    maxWidth: 960,
    marginBottom: '250px'
  },
  appLarge: {},
  adSpace: {
    width: '100%',
    textAlign: 'center'
  },
  footer: {
    height: '100px', // 90 (ad) + 50 (footer)
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    textAlign: 'center'
  },
  footerAd: {
    padding: '0 10px',
    textAlign: 'center',
    height: 100,
    width: '750px',
    marginWidth: 0,
    margin: '1em auto 0 auto',
    border: 'none',
    frameBorder: 0
  },
  footerContent: {
    backgroundColor: '#efefef',
    padding: '1rem'
  },
  ujcLogo: {}
}

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps)
)(App)
