import React, { Component } from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

class AdminPage extends Component {
  render () {
    const { classes } = this.props
    return <div>
      <Typography variant='h5'>Admin Home</Typography>
      <Paper className={classes.root}>
        <Typography variant='h6'>Quick Links</Typography>
        <Button variant='contained' component={Link} to='/admin/templates' >Edit Templates</Button>
        <Button variant='contained' component={Link} to='/admin/sections' >Edit Sections</Button>
      </Paper>
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  iconButton: {
    width: 30,
    height: 30
  }
})

export default withStyles(styles)(AdminPage)
