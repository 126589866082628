import React, { Component } from 'react'

class WhatShouldIIncludeInMyLoveLetter extends Component {
  render () {
    return (
      <div>
        <p>Maybe you’ve heard stories real estate love letters being successful, and maybe you have a friend who used this tool to stand out over other competitors when buying their home; maybe you were skeptical, and possibly you still are, but now that you are in the market to buy a home of your own, and now that you’ve located your dream house, you want to avoid bidding wars, stay within your budget, and make your offer as strong as you possibly can - but you’re not sure of what to include in your love letter. You don’t want to write something that a seller will ignore or find irrelevant, and you want to make the most of the opportunity to explain your offer and commitment to the seller. While no two winning love letters will be the same, there are basic ingredients that will give your letter the best focus and best odds of acceptance.</p>
        <h3>Include points that strengthen your offer</h3>
        <p>The basic aim of a love letter is to strengthen your home buying offer, to make it stand out amid multiple offers and to help a seller understand your goals and expectations. You know your financial situation and at the moment of writing an offer, there is nothing you can do to improve it further; if you cannot indulge in a bidding war with other buyers, you’ll need to compete on terms and timelines, and a home love letter helps you do just that. By writing directly to the seller, you have an opportunity to introduce yourself as a serious and committed buyer whom the seller would be glad to work with, and you have the ability to summarize the strengths of your offer in a narrative format, so that it makes a stronger impression and stands out in a sea of dates and numbers. Reassure the seller that you will be a worthy owner of their home, and will cherish and maintain it as they have, and discuss the ways your situation is a benefit to the seller. Are you flexible in when you move, or willing to keep existing tenants? Can you close quickly with your specific lender, or do you have renovation experience that means you’re unphased by inspections? Let the seller know.</p>
        <h3>Tell them how much you love their home</h3>
        <p>It’s natural for buyers to point out shortcomings in the condition and features of a home, even if they love it; this instinct stems from wanting a “good deal” and hoping to win the house with a lower offer. At the offer letter phase, though, you should include specific points and features that attract you to this home and neighborhood; even if an owner could care less, this demonstrates your commitment to seeing the purchase through, and a seller will feel more confident accepting your offer if they believe you won’t back out. A little flattery doesn’t hurt: we all have a soft spot for others who appreciate our aesthetic choices, and putting a home up for sale is a very public test of one’s design and decor tastes. Appeal to this with a genuine compliment or two, and you’ll both show your dedication and connect with the seller personally. Additionally, complimenting current features of the home and the care that they’ve taken of those features, implies to sellers that you will maintain the property in the future and enjoy it as they have.</p>
        <h3>Reassure seller with finances and flexibility</h3>
        <p>Much of the strategy of a real estate love letter revolves around demonstrating commitment and qualification in creative, narrative ways, but you also should be explicit: remind the seller of the ways in which you can be flexible and meet their needs as part of the sale. Reiterate explicitly if your moving timeline is flexible, or if you’re a first-time buyer, remind them that it means your purchase is not contingent on the sale of your home. Restate your relationship with your lender and let the seller know that your agent can connect them to your mortgage representative if they have doubts. You may not have spare cash to throw at the purchase, but you can show that you have worked out financing in advance and are well prepared.</p>
        <p>Remember, even if you can’t beat other buyers with a higher offer, you can use an offer letter to present yourself as a serious, committed, and caring buyer who is in love with the home and is committed to the purchase. Your real estate love letter can strike a chord with a seller that makes your offer stand out, and standing out can get it accepted.</p>
      </div>
    )
  }
}

export default WhatShouldIIncludeInMyLoveLetter
