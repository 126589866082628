import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

/**
 * Redirector
 *
 * Handles redirects within React Router for any redirect pushed to global/redirect
 */
export default class Redirector extends Component {
  static propTypes = {
    redirect: PropTypes.string,
    clearRedirect: PropTypes.func
  }

  static defaultProps = {
    redirect: null,
    clearRedirect: () => {}
  }

  componentDidUpdate (prevProps) {
    if ((this.props.redirect !== prevProps.redirect) && this.props.redirect !== null) {
      this.props.clearRedirect()
    }
  }

  render = () => {
    return this.props.redirect && <Redirect to={this.props.redirect} />
  }
}
