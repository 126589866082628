import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import { ListItem, ListItemIcon, ListItemText, IconButton, Drawer, Divider, ListSubheader } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Edit, FormatAlignLeft, ShortText, Input } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Roles } from './SecureRoute'
import { CONSTANTS, ENVIRONMENTS } from '../helpers/Constants'

class MenuDrawer extends Component {
  static propTypes = {
    open: PropTypes.bool,
    copyHandler: PropTypes.func
  }

  constructor (props) {
    super(props)

    this.state = {
      drawerOpen: false
    }
  }

  /**
   * Redirect to promo site
   */
  handlePromoLink = () => {
    window.open('http://katebostonrealestate.com', '_blank')
  }

  handleClose = () => this.setState({drawerOpen: false})

  render = () =>
    <div>
      <IconButton
        color='secondary'
        onClick={() => this.setState({drawerOpen: true})}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='right'
        open={this.state.drawerOpen}
        onClose={this.handleClose}
        onClick={this.handleClose}
      >
        <div>
          <ListItem button component={Link} to='/search'>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary='Start a Letter' />
          </ListItem>
          <ListItem button component={Link} to='/articles'>
            <ListItemIcon>
              <FormatAlignLeft />
            </ListItemIcon>
            <ListItemText primary='Love Letter Articles' />
          </ListItem>
          <ListItem button onClick={this.handlePromoLink}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Start House Hunting...' />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to='/login'>
            <ListItemIcon>
              <Input />
            </ListItemIcon>
            <ListItemText primary='Login' />
          </ListItem>
          <Divider />
          {this.props.roles.includes(Roles.ADMIN) && <div>
            <ListSubheader>Admin</ListSubheader>
            <ListItem button component={Link} to='/admin/templates' >
              <ListItemIcon>
                <FormatAlignLeft />
              </ListItemIcon>
              <ListItemText primary='Edit Templates' />
            </ListItem>
            <ListItem button component={Link} to='/admin/sections' >
              <ListItemIcon>
                <ShortText />
              </ListItemIcon>
              <ListItemText primary='Edit Sections' />
            </ListItem>
          </div>}
        </div>
      </Drawer>
    </div>
}

const mapStateToProps = state => {
  return {roles: state.user.roles}
}

export default connect(mapStateToProps)(MenuDrawer)
