import React, { Component } from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class MyLettersPage extends Component {
  render () {
    const { classes } = this.props
    return <div>
      <Typography variant='h5'>My Letters</Typography>
      <Paper className={classes.root}>
        <Typography variant='h6'>Start a New Letter</Typography>
        <Button>Start a New Letter</Button>
      </Paper>
      <Paper className={classes.root}>
        <Typography variant='h6'>Continue Working on a Letter</Typography>
        <p>List of in-progress letters</p>
        <p><em>Clicking "finalize" for a letter moves it to the lower category with a message: this operation
          cannot be undone. Once you finalize a letter you can no longer edit it in the template tool, but you can
          tweak the text directly.
        </em></p>
      </Paper>
      <Paper className={classes.root}>
        <Typography variant='h6'>Tweak a Finalized Letter</Typography>
        <p>List of finalized letters</p>
      </Paper>
      <Paper className={classes.root}>
        <Typography variant='h6'>My Details</Typography>
        <p>This is a list of dictionary items the person has filled in.</p>
      </Paper>
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  iconButton: {
    width: 30,
    height: 30
  }
})

export default withStyles(styles)(MyLettersPage)
