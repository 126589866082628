import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { fetchAllTemplates, deleteTemplate } from '../actions/template'
import { redirect } from '../actions/global'
import { FormatAlignLeft, Clear } from '@material-ui/icons'
import { ListItemSecondaryAction, Paper, List, Chip, IconButton, Tooltip, withWidth } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TagDialog from '../components/TagDialog'
import { isWidthUp } from '@material-ui/core/withWidth'

const TagArray = ({ tags, isSmall, chipClass, handleClickChip, handleClickAllTags }) => {
  const tagLength = tags.length
  if (isSmall) {
    if (tagLength > 0) {
      return <Chip
        key='rest'
        label={`+${tagLength}`}
        className={chipClass}
        onClick={() => handleClickAllTags(tags)}
      />
    } else {
      return null
    }
  } else {
    const displayTags = tags.slice(0, 3)
    return <div>
      {displayTags.map(tag => (
        <Chip
          key={tag}
          label={tag}
          className={chipClass}
          onClick={() => handleClickChip(tag)}
        />
      ))}
      {tagLength > 3 &&
      <Chip
        key='rest'
        label={`+${tagLength - 3}`}
        className={chipClass}
        onClick={() => handleClickAllTags(tags)}
      />
      }
    </div>
  }
}

const TemplateRow = ({templateData, classes, handleClickChip, handleClickAllTags, isSmall}) =>
  <ListItem
    button
    component={Link}
    to={`/write/new/${templateData._id}`}
  >
    <ListItemAvatar>
      <Avatar>
        <FormatAlignLeft />
      </Avatar>
    </ListItemAvatar>
    <Tooltip title={templateData.description} placement='top' enterDelay={500}>
      <ListItemText
        primary={templateData.title}
        secondary={templateData.description}
      />
    </Tooltip>
    <ListItemSecondaryAction>
      <TagArray
        tags={_.get(templateData, 'tags', [])}
        isSmall={isSmall}
        chipClass={classes.chip}
        handleClickChip={handleClickChip}
        handleClickAllTags={handleClickAllTags}
      />
    </ListItemSecondaryAction>
  </ListItem>

class SearchPage extends Component {
  constructor (props) {
    super(props)
    props.fetchAllTemplates()
    this.state = {
      showTagDialog: false,
      tagDialogTags: []
    }
  }

  handleClickAllTags = tags => {
    this.setState({
      showTagDialog: true,
      tagDialogTags: tags
    })
  }

  handleCloseTagDialog = tag => {
    this.setState({showTagDialog: false})
    if (tag) this.handleClickChip(tag)
  }

  handleClickChip = chip => {
    this.props.redirect(`/search/${chip}`)
  }

  showFetchingError = () => (
    !this.props.isFetchingTemplates && this.props.fetchTemplatesStatus === false
  )

  showTemplates = () => (
    !this.props.isFetchingTemplates && this.props.fetchTemplatesStatus
  )

  showTemplate = (filter, template) => {
    if (!filter) {
      return true
    } else {
      return _.get(template, 'tags', []).includes(filter)
    }
  }

  handleClearFilter = () => {
    this.props.redirect('/search')
  }

  render () {
    const filter = _.get(this.props, ['match', 'params', 'tag'], false)
    const { classes } = this.props
    return (
      <div>
        <div className={classes.pageHeader}>
          <Typography variant='h6'>
            Search Templates
          </Typography>
          <p>Choose a template that matches your situation. You can click on tags to filter the templates.</p>
          {filter &&
          <div>Filtering by: <strong>{filter}</strong>
            <IconButton className={classes.signOut} onClick={this.handleClearFilter} aria-label='Clear Filter'>
              <Clear className={classes.clearButton} />
            </IconButton>
          </div>
          }
        </div>
        {this.showFetchingError() && <p>Error, could not load templates: {this.props.fetchTemplatesMessage}</p>}
        {this.showTemplates() &&
        <Paper>
          <List dense>
            {Object.getOwnPropertyNames(this.props.templateCache).map(templateId => {
              const template = this.props.templateCache[templateId]
              return (this.showTemplate(filter, template) &&
                <TemplateRow
                  key={template._id}
                  templateData={template}
                  classes={classes}
                  handleClickChip={this.handleClickChip}
                  handleClickAllTags={this.handleClickAllTags}
                  isSmall={!isWidthUp('sm', this.props.width)}
                />)
            })}
          </List>
        </Paper>
        }
        <TagDialog
          open={this.state.showTagDialog}
          chipClass={classes.chip}
          tags={this.state.tagDialogTags}
          handleClose={this.handleCloseTagDialog}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    isFetchingTemplates,
    fetchTemplatesStatus,
    fetchTemplatesMessage,
    templateCache} = state.template
  return {
    isFetchingTemplates,
    fetchTemplatesStatus,
    fetchTemplatesMessage,
    templateCache
  }
}

const mapDispatchToProps = dispatch => ({
  deleteTemplate: id => dispatch(deleteTemplate(id)),
  fetchAllTemplates: () => dispatch(fetchAllTemplates()),
  redirect: to => dispatch(redirect(to))
})

const styles = theme => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },

  dense: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  pageHeader: {
    paddingBottom: '1em'
  },
  chip: {
    margin: 4
  },
  signOut: {
    height: 20,
    width: 20
  }
})

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchPage)
