import {
  REDIRECT, CLEAR_REDIRECT, SHOW_NOTIFICATION, CLEAR_NOTIFICATION, SET_USER_LOCATION
} from '../actions/actionTypes'

const initialState = {
  redirect: null,
  notification: null
}

const global = (state = initialState, action) => {
  switch (action.type) {
    case REDIRECT:
      return Object.assign({}, state, {redirect: action.redirect})

    case CLEAR_REDIRECT:
      return Object.assign({}, state, {redirect: null})

    case SHOW_NOTIFICATION:
      return Object.assign({}, state, {notification: action.notification})

    case CLEAR_NOTIFICATION:
      return Object.assign({}, state, {notification: null})

    case SET_USER_LOCATION:
      return Object.assign({}, state, {userLocation: action.location})

    default:
      return state
  }
}

export default global
