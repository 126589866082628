import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

class LetterEditorFillinText extends Component {
  static propTypes = {
    properties: PropTypes.object,
    value: PropTypes.string,
    handleUpdate: PropTypes.func,
  }

  static defaultProps = {
    properties: {},
    value: '',
    handleUpdate: () => {}
  }

  constructor (props) {
    super(props)
    this.textInput = React.createRef()
    if (!props.value && props.properties.defaultValue) props.handleUpdate(props.properties.defaultValue)
  }

  componentDidMount () {
    if (this.textInput) this.textInput.focus()
  }

  componentDidUpdate () {
    if (this.textInput) this.textInput.focus()
  }

  render () {
    const { size = 'medium' } = this.props.properties
    const { value, handleUpdate } = this.props
    return (
      <TextField
        size={size}
        value={value}
        onChange={e => handleUpdate(e.target.value)}
        inputRef={(input) => { this.textInput = input }}
      />
    )
  }
}

export default LetterEditorFillinText
