import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Grid, TextField
} from '@material-ui/core'

export class FillinAccordionText extends Component {
  static propTypes = {
    fillinKey: PropTypes.string.isRequired,
    properties: PropTypes.object,
    handleUpdateProperty: PropTypes.func.isRequired
  }

  static defaultProps = {
    fillinKey: '',
    properties: {}
  }

  render () {
    const {
      label = '',
      defaultValue = '',
      helperText = '',
      size = 'medium',
      dictionaryEntry = ''
    } = this.props.properties
    const { handleUpdateProperty } = this.props

    return <form>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id='label'
            label='Label'
            value={label}
            onChange={e => handleUpdateProperty('label', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='defaultValue'
            label='Default Value'
            value={defaultValue}
            onChange={e => handleUpdateProperty('defaultValue', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='helperText'
            label='Helper Text'
            value={helperText}
            onChange={e => handleUpdateProperty('helperText', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='size'
            label='Size'
            type='number'
            value={size}
            onChange={e => handleUpdateProperty('size', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='dictionaryEntry'
            label='Dictionary Entry'
            helperText='Automatically fill from user dictionary.'
            value={dictionaryEntry}
            onChange={e => handleUpdateProperty('dictionaryEntry', e.target.value)}
          />
        </Grid>
      </Grid>
    </form>
  }
}
