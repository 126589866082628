
export async function loadLocation (setUserLocation, userLocation) {
  // set if location is not set in redux
  if (!userLocation) {
    // check if location is set in local storage
    let location = window.localStorage.getItem('reloveletterUserLocation')
    if (location && typeof location !== 'object') location = JSON.parse(location)
    // if it is, set the state from local storate
    if (location) {
      setUserLocation(location, setUserLocation)
    } else {
      // Get location from geolocation
      let location = null
      let locationObject
      if (navigator.geolocation) {
        location = await getLocationByGeolocation()
        if (location) {
          locationObject = {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            city: null,
            state: null,
            country: null
          }
        }
      }

      // On rejection/falure, fall back to API
      if (!location) {
        location = await getLocationByAPI()
        locationObject = {
          latitude: location.latitude,
          longitude: location.longitude,
          city: location.city,
          state: location.region,
          country: location.country_code
        }
      }

      let distance
      try {
        const lat1 = 42.290055
        const lon1 = -71.129215
        const lat2 = locationObject.latitude
        const lon2 = locationObject.longitude

        const R = 3959 // radius of earth in miles
        const φ1 = toRadians(lat1)
        const φ2 = toRadians(lat2)
        const Δφ = toRadians(lat2 - lat1)
        const Δλ = toRadians(lon2 - lon1)

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

        distance = R * c
      } catch (error) {
        console.log(`Failed to calculate distance ${error}`)
      }

      locationObject.distance = distance || 1000
      // save to local storage
      window.localStorage.setItem('reloveletterUserLocation', JSON.stringify(locationObject))
      // set in state
      setUserLocation(locationObject)
    }
  }
}

const toRadians = number => number * Math.PI / 180

function getLocationByGeolocation () {
  return new Promise((resolve) =>
    navigator.geolocation.getCurrentPosition(position => resolve(position), () => resolve(false))
  )
}

async function getLocationByAPI () {
  const apiResponse = await fetch('https://api.ipdata.co/?api-key=d292479e5472071e3bf3bf34388234519c838f2e9c7508aae108c527')
  return apiResponse.json()
}
