import {
  FETCH_TEMPLATES_BEGIN,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_ABORT,
  FETCH_SINGLE_TEMPLATE_BEGIN,
  FETCH_SINGLE_TEMPLATE_SUCCESS,
  FETCH_SINGLE_TEMPLATE_FAILURE,
  FETCH_SINGLE_TEMPLATE_ABORT,
  INVALIDATE_TEMPLATE_CACHE,
  SET_CURRENT_TEMPLATE,
  DELETE_TEMPLATE_BEGIN, DELETE_TEMPLATE_SUCCESS, DELETE_TEMPLATE_FAILURE,
  NEW_TEMPLATE_BEGIN, NEW_TEMPLATE_SUCCESS, NEW_TEMPLATE_FAILURE,
  EDIT_TEMPLATE_BEGIN, EDIT_TEMPLATE_SUCCESS, EDIT_TEMPLATE_FAILURE
} from '../actions/actionTypes'

const initialState = {
  isFetchingTemplates: false,
  fetchTemplatesStatus: null,
  fetchTemplatesMessage: '',
  isFetchingSingleTemplate: false,
  fetchSingleTemplateStatus: null,
  fetchSingleTemplateMessage: '',
  templateCache: {},
  templateCacheTimestamp: null,
  currentTemplate: null,
  isDeletingTemplate: false,
  deleteTemplateStatus: null,
  deleteTemplateMessage: '',
  isAddingTemplate: false,
  newTemplateStatus: null,
  newTemplateMessage: ''
}

const template = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATES_BEGIN:
      return Object.assign({}, state, {
        isFetchingTemplates: true,
        fetchTemplatesStatus: null,
        fetchTemplatesMessage: ''
      })
    case FETCH_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        isFetchingTemplates: false,
        fetchTemplatesStatus: true,
        fetchTemplatesMessage: `Successfully fetched ${action.templates.length} templates.`,
        templateCache: action.templates,
        templateCacheTimestamp: Date.now()
      })
    case FETCH_TEMPLATES_FAILURE:
      return Object.assign({}, state, {
        isFetchingTemplates: false,
        fetchTemplatesStatus: false,
        fetchTemplatesMessage: `Failed to fetch templates: ${action.errorMessage}`
      })
    case FETCH_TEMPLATES_ABORT:
      return Object.assign({}, state, {
        isFetchingTemplates: false,
        fetchTemplatesStatus: true,
        fetchTemplatesMessage: 'Templates up-to-date.'
      })
    case FETCH_SINGLE_TEMPLATE_BEGIN:
      return Object.assign({}, state, {
        isFetchingSingleTemplate: true,
        fetchSingleTemplateStatus: null,
        fetchSingleTemplateMessage: ''
      })
    case FETCH_SINGLE_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingSingleTemplate: false,
        fetchSingleTemplateStatus: true,
        fetchSingleTemplateMessage: `Successfully fetched template ${action.template.title}.`,
        templateCache: Object.assign({}, state.templateCache, {[action.template._id]: action.template})
      })
    case FETCH_SINGLE_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingSingleTemplate: false,
        fetchSingleTemplateStatus: false,
        fetchSingleTemplateMessage: 'Failed to fetch single template!'
      })
    case FETCH_SINGLE_TEMPLATE_ABORT:
      return Object.assign({}, state, {
        isFetchingSingleTemplate: false,
        fetchSingleTemplateStatus: true,
        fetchSingleTemplateMessage: 'Template up-to-date.',
        currentTemplate: action.id
      })
    case INVALIDATE_TEMPLATE_CACHE:
      return Object.assign({}, state, {
        templateCacheTimestamp: null
      })
    case SET_CURRENT_TEMPLATE:
      return Object.assign({}, state, {
        currentTemplate: action.id
      })
    case DELETE_TEMPLATE_BEGIN:
      return Object.assign({}, state, {
        isDeletingTemplate: true
      })
    case DELETE_TEMPLATE_SUCCESS:
      const { [action.id]: value, ...newCache } = state.templateCache
      return Object.assign({}, state, {
        isDeletingTemplate: false,
        deleteTemplateStatus: true,
        deleteTemplateMessage: 'Successfully deleted template',
        templateCache: newCache
      })
    case DELETE_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        isDeletingTemplate: false,
        deleteTemplateStatus: false,
        deleteTemplateMessage: `Failed to delete template: ${action.error}`
      })
    case NEW_TEMPLATE_BEGIN:
      return Object.assign({}, state, {
        isAddingTemplate: true
      })
    case NEW_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isAddingTemplate: false,
        newTemplateStatus: true,
        newTemplateMessage: 'Successfully created new template.',
        templateCache: Object.assign({}, state.templateCache, {
          [action.template._id]: action.template
        })
      })
    case NEW_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        isAddingTemplate: false,
        newTemplateStatus: false,
        newTemplateMessage: action.error
      })
    case EDIT_TEMPLATE_BEGIN:
      return Object.assign({}, state, {
        isEditingTemplate: true
      })
    case EDIT_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        isEditingTemplate: false,
        editTemplateStatus: true,
        editTemplateMessage: 'Successfully edited template.',
        templateCache: Object.assign({}, state.templateCache, {
          [action.template._id]: action.template
        })
      })
    case EDIT_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        isEditingTemplate: false,
        editTemplateStatus: false,
        editTemplateMessage: action.error
      })
    default:
      return state
  }
}

export default template
