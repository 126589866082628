import React, { Component } from 'react'
import * as _ from 'lodash'
import {
  IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ArrowDownward, ArrowUpward, RemoveCircleOutline } from '@material-ui/icons'

class TemplateEditorSectionsList extends Component {
  isLast = (position, count) => position === count - 1

  render () {
    const { classes, dirtySections, allSections, moveSectionUp, moveSectionDown, removeSectionFromTemplate } = this.props
    const sectionCount = dirtySections.length

    return (
      <Paper className={classes.root}>
        <Typography variant='h6'>Sections</Typography>
        <List>
          {sectionCount > 0 && dirtySections.map((sectionKey, index) => {
            //const section = allSections[sectionKey]
            const title = _.get(allSections, [sectionKey, 'title'], null)
            const titleNode = title
              ? <span>{title}</span>
              : <span className={classes.error}>Section not found!</span>
            return <ListItem key={`${sectionKey}${index}`}>
              <ListItemText primary={titleNode} />
              <ListItemSecondaryAction>
                {index > 0 &&
                <IconButton
                  className={classes.iconButton}
                  aria-label='Move Up'
                  style={{marginRight: this.isLast(index, sectionCount) ? '30px' : ''}}
                  onClick={() => moveSectionUp(index)}
                >
                  <ArrowUpward />
                </IconButton>
                }
                {!this.isLast(index, sectionCount) &&
                <IconButton
                  className={classes.iconButton}
                  aria-label='Move Down'
                  onClick={() => moveSectionDown(index)}
                >
                  <ArrowDownward />
                </IconButton>
                }
                <IconButton className={classes.iconButton} aria-label='Remove' onClick={() => removeSectionFromTemplate(index)}>
                  <RemoveCircleOutline />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          })}
          {dirtySections.length === 0 && <p>No Sections</p>}
        </List>
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  iconButton: {
    width: 30,
    height: 30
  },
  error: {
    color: 'red'
  }
})

export default withStyles(styles)(TemplateEditorSectionsList)
