import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

class SectionPreview extends Component {
  static propTypes = {
    text: PropTypes.string,
    fillins: PropTypes.object,
    handleSelectFillin: PropTypes.func,
    selectedFillin: PropTypes.string
  }

  static defaultProps = {
    text: '',
    fillins: {}
  }

  generatePreview = (template, fillins) => {
    let match
    let segments = []

    let fillinCount = 0
    // iterate over template while match is true
    // eslint-disable-next-line
    while(match = template.match(/{([a-f0-9]+)}/)) {
      // push text section to array
      segments.push(
        <span
          className={this.props.classes.textSegment}
          key={`text${fillinCount}`}
        >
          {template.substr(0, match.index)}
        </span>
      )

      // get fillin id
      let fillinKey = match[1]
      let fillinInfo = fillins[fillinKey]

      let fillinClasses
      if (fillinKey === this.props.selectedFillin) {
        fillinClasses = `${this.props.classes.previewFillin} ${this.props.classes.selectedFillin}`
      } else {
        fillinClasses = `${this.props.classes.previewFillin}`
      }

      // lookup captured tag in fillins property
      segments.push((fillinInfo && fillinInfo.properties)
        ? <strong
          key={fillinKey}
          className={fillinClasses}
          onClick={() => this.props.handleSelectFillin(fillinKey)}
        >
          {fillinInfo.properties.label}
        </strong>
        : <span key={fillinKey} style={{color: 'red'}}>Not Found</span>
      )

      // chop template after the indicator
      template = template.substr(match.index + match[0].length)
      fillinCount++
    }

    // Push any remainder
    segments.push(<span className='textSegment' key={`remainder`}>{template}</span>)

    return segments
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {this.generatePreview(this.props.text, this.props.fillins)}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  previewFillin: {
    cursor: 'pointer'
  },
  selectedFillin: {
    textDecoration: 'underline'
  },
  textSegment: {
    whiteSpace: 'pre-wrap'
  }
})

export default withStyles(styles)(SectionPreview)
