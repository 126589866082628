import React, { Component } from 'react'

class WhyDoINeedALoveLetter extends Component {
  render () {
    return (
      <div>
        <p>The phrase “love letter” evokes images of an emotional plea, a baring of the soul. For home buyers in competitive markets, love letter also refers to an offer letter written by an interested buyer, to be included in an offer presented to a seller. In a housing market where there are many buyers submitting offers for a single property, it is the love letter that often separates the winning offer from the rest of the pack. But why?</p>
        <h3>A real estate love letter represents your commitment to the purchase and the home</h3>
        <p>Unless you’re purchasing below your max budget with room to move upward in price, merely submitting your offer doesn’t set you apart in the mind of the seller in a competitive market. If you also submit a letter to the seller along with your offer, you can convey your dedication to the home and your future there.</p>
        <h3>You can talk directly with the seller on a personal level</h3>
        <p>A love letter gives you a chance to pitch your offer directly to the seller. This is where you can win them over and demonstrate that you will be excellent to do business with, and will be a steward of their home for years to come. Express to the seller why their home means so much to you, and set yourself up as the best choice for them as a buyer.</p>
        <h3>Tell the seller why selling to you would take worries of their mind</h3>
        <p>Your love letter should make it clear that you are a serious buyer, committed to the purchase and prepared to see it through. Give the owner reason to believe that you will not develop cold feet at a later stage of the process, and reassure them that you will not nickel and dime or generate drama after inspection. Beyond your love for the home, use your real estate love letter to emphasize your flexibility on timelines and terms for the sale.</p>
      </div>
    )
  }
}

export default WhyDoINeedALoveLetter
