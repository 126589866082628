import React, { Component } from 'react'

class QuizPage extends Component {
  static propTypes = {}

  static defaultProps = {}

  render () {
    return (
      <div>
        <p>Quiz Page</p>
      </div>
    )
  }
}

export default QuizPage
