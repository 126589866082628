import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, FormControl,
  Grid, InputLabel, MenuItem, Paper, Select,
  TextField, Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FillinAccordion from './FillinAccordion'
import AddIcon from '@material-ui/icons/Add'
import { FILLIN_LOOKUP } from '../helpers/Constants'
import { v4 as uuid } from 'uuid'
import SectionPreview from './SectionPreview'

class SectionEditor extends Component {
  static propTypes = {
    section: PropTypes.object,
    commitSection: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      sectionId: null,
      dirtyTitle: '',
      dirtyText: '',
      dirtyFillins: {},
      newFillinType: '0',
      openAccordion: null
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (
      prevState.sectionId === null &&
      nextProps.section && nextProps.section._id !== null
    ) {
      return {
        sectionId: nextProps.section._id,
        dirtyTitle: nextProps.section.title || '',
        dirtyText: nextProps.section.text || '',
        dirtyFillins: nextProps.section.fillins || {}
      }
    } else {
      return null
    }
  }

  handleSaveSection = () => {
    this.props.commitSection({
      title: this.state.dirtyTitle,
      text: this.state.dirtyText,
      fillins: this.state.dirtyFillins
    })
  }

  handleNewFillin = () => {
    // Add a new property at random uuid to dirtyFillins
    const randomKey = uuid().substring(0, 7)
    const newFillin = {
      key: randomKey,
      type: parseInt(this.state.newFillinType, 10),
      properties: {}
    }

    const newDirtyFillins = Object.assign({}, this.state.dirtyFillins, {[randomKey]: newFillin})
    this.setState({
      dirtyFillins: newDirtyFillins,
      openAccordion: randomKey
    })
  }

  handleDeleteFillin = (fillinKey) => {
    if (window.confirm('Are you sure you want to delette this fillin?')) {
      const {[fillinKey]: toDelete, ...remainingFillins} = this.state.dirtyFillins
      this.setState({dirtyFillins: Object.assign({}, remainingFillins)})
    }
  }

  handleUpdateFillinProperty = (fillinKey, property, value) => {
    // update dirty properties with new properties object
    const { [fillinKey]: fillinData } = this.state.dirtyFillins
    // update or add the property
    const baseFillinData = fillinData ? fillinData.properties : {}
    const newFillinProperties = Object.assign({}, baseFillinData, {[property]: value})
    const newFillin = Object.assign({}, fillinData, {properties: newFillinProperties})

    this.setState({
      dirtyFillins: Object.assign({}, this.state.dirtyFillins, {[fillinKey]: newFillin})
    })
  }

  handleChangeAccordion = panel => (e, expanded) => {
    this.setState({openAccordion: this.state.openAccordion === panel ? null : panel})
  }

  handleSelectFillin = key => {
    this.setState({openAccordion: key})
  }

  render () {
    const { classes } = this.props

    return <div>
      {this.props.section
        ? <Typography variant='subtitle1'>Editing section {this.props.section.title}</Typography>
        : <Typography variant='subtitle1'>Creating new section</Typography>}
      <Grid container spacing={3} style={{width: '100%'}}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.root}>
            <TextField
              id='sectionTitle'
              type='text'
              label='Title'
              fullWidth
              value={this.state.dirtyTitle}
              onChange={(e) => this.setState({dirtyTitle: e.target.value})}
            /> <br />
            <TextField
              id='sectionText'
              type='text'
              label='Text'
              helperText='Add fillins with tags like {796a9ef}.'
              multiline
              fullWidth
              minRows={3}
              maxRows={10}
              value={this.state.dirtyText}
              onChange={(e) => this.setState({dirtyText: e.target.value})}
            />
          </Paper>
          <Paper className={classes.root}>
            <Typography variant='h6' className={classes.header}>Preview</Typography>
            <SectionPreview
              text={this.state.dirtyText}
              fillins={this.state.dirtyFillins}
              handleSelectFillin={this.handleSelectFillin}
              className={classes.sectionPreview}
            />
          </Paper>
          <Button
            variant='outlined'
            color='primary'
            onClick={this.handleSaveSection}
          >
            {this.props.section ? 'Save Changes' : 'Create Section'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.root}>
            <Typography variant='h6' className={classes.header}>Fillins</Typography>
            {Object.keys(this.state.dirtyFillins).map(key =>
              <FillinAccordion
                key={key}
                fillin={this.state.dirtyFillins[key]}
                expanded={this.state.openAccordion === key}
                handleChange={this.handleChangeAccordion}
                handleUpdateProperty={(property, value) => this.handleUpdateFillinProperty(key, property, value)}
                handleDeleteFillin={() => this.handleDeleteFillin(key)}
              />
            )}
            <Grid container justifyContent='space-between'>
              <Grid item xs={8}>
                <FormControl className={classes.addFillinDropdown}>
                  <InputLabel htmlFor='fillin-type'>Fillin Type</InputLabel>
                  <Select
                    value={this.state.newFillinType}
                    onChange={e => this.setState({newFillinType: e.target.value})}
                    inputProps={{
                      name: 'fillin-type',
                      id: 'fillin-type'
                    }}
                  >
                    {Object.keys(FILLIN_LOOKUP).map(key =>
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {FILLIN_LOOKUP[key]}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.addFillinButton}>
                <Button variant='outlined' size='small' color='primary' aria-label='add' onClick={this.handleNewFillin}>
                  <AddIcon /> Fillin
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  header: {
    paddingBottom: theme.spacing(2)
  },
  addFillinDropdown: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  addFillinButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end'
  },
  sectionPreview: {
    paddingBottom: '1em'
  }
})

export default withStyles(styles)(SectionEditor)
