import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import {
  Button,
  Grid, Paper,
  TextField,
  Typography,
  withWidth,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core'
import { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { PictureAsPdf, Print, Create, Favorite } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import LetterEditorFillinWidget from './LetterEditorFillinWidget'
import LetterSectionPreview from './LetterSectionPreview'
import JsPdf from 'jspdf'
import { showNotification } from '../actions/global'
import { CONSTANTS, ENVIRONMENTS } from '../helpers/Constants'
import sanitizeHtml from 'sanitize-html'
import { Link, Prompt } from 'react-router-dom'
import classNames from 'classnames'
import FinalizeLetterWidget from './FinalizeLetterWidget'

class LetterEditor extends Component {
  static propTypes = {
    template: PropTypes.object,
    commitTemplate: PropTypes.func,
    allSections: PropTypes.object,
    letterValues: PropTypes.object,
    sectionNotesEnabled: PropTypes.bool
  }

  static defaultProps = {
    template: {},
    commitTemplate: () => {},
    allSections: {},
    letterValues: {},
    sectionNotesEnabled: false
  }

  static contextTypes = {
    logger: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {
      templateId: null,
      dirtyLetterNotes: '',
      dirtyLetterValues: {},
      selectedFillin: null,
      dirty: false,
      showFinalizationDialog: false,
      isFinalizeMode: false,
      finalizeText: ''
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (
      prevState.templateId === null &&
      nextProps.template &&
      nextProps.template._id !== null
    ) {
      return {
        templateId: nextProps.template._id
      }
    } else {
      return null
    }
  }

  handleSelectFillin = fillinKey => {
    if (typeof fillinKey === 'string') {
      this.setState({selectedFillin: fillinKey})
    }
  }

  handleDeSelectFillin = () => {
    this.setState({selectedFillin: null})
  }

  handleUpdateFillinValue = (fillinKey, value) => {
    if (!this.state.dirty) window.onbeforeunload = () => true
    // set value for the given fillin key
    this.setState({
      dirtyLetterValues: Object.assign({}, this.state.dirtyLetterValues, {[fillinKey]: value}),
      dirty: true
    })
  }

  handleSaveLetter = (print = false, finalizeMode = false) => {
    try {
      let pdfText = ''

      // For finalize mode, use the finalize text, otherwise generate string
      if (this.state.isFinalizeMode) {
        pdfText = this.state.finalizeText
      } else {
        // Check that all fillins are filled
        const templateSections = this.getTemplateSections()
        if (CONSTANTS.ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
          const fillinCount = templateSections.reduce((acc, section) => { return acc + Object.keys(section.fillins).length }, 0)
          const fillinValueCount = Object.keys(this.state.dirtyLetterValues).length
          if (fillinCount !== fillinValueCount) throw new Error('Not all fillins are filled in.')
        }

        // Generate text
        // generate text from section with fillins

        // iterate over matches until each is replaced
        templateSections.forEach(section => {
          let match
          let newSectionText = section.text
          while (match = newSectionText.match(/{([a-f0-9]+)}/)) { // eslint-disable-line no-cond-assign
            let fillinValue = _.get(this.state, ['dirtyLetterValues', match[1]], '[MISSING FILLIN]')
            fillinValue = sanitizeHtml(fillinValue)
            newSectionText = newSectionText.replace(new RegExp('\\{' + match[1] + '\\}'), fillinValue)
          }
          pdfText = pdfText.length ? `${pdfText}\n\n${newSectionText}` : newSectionText
        })
      }

      // Generate PDF
      const marginLeftRight = 1
      const marginTopBottom = 1
      const pageWidth = 8.5
      const pageHeight = 11
      const pointsToInches = 0.0138889
      const fontSize = 14
      const lineSpacing = 1.05

      let doc = new JsPdf({unit: 'in'})
      doc.setFont('helvetica')
      doc.setFontSize(fontSize)
      doc.setTextColor(0)

      // Breaks text into lines
      const preparedText = doc.splitTextToSize(pdfText, pageWidth - marginLeftRight * 2)

      // Breaks lines into pages
      let i, j
      let pages = []
      let chunk = Math.floor((pageHeight - marginTopBottom * 2) / (fontSize * pointsToInches * lineSpacing))
      for (i = 0, j = preparedText.length; i < j; i += chunk) {
        pages.push(preparedText.slice(i, i + chunk))
      }

      // Adds each page to the document
      pages.forEach((page, index) => {
        doc.text(marginLeftRight, marginTopBottom, page)
        if (index < pages.length - 1) doc.addPage()
      })

      if (print) {
        doc.autoPrint()
        const string = doc.output('datauristring')
        const iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
        const x = window.open()
        x.document.open()
        x.document.write(iframe)
        x.document.close()
      } else {
        doc.save(`love_letter_${_.get(this.props, ['template', 'title'], 'doc')}.pdf`)
      }
    } catch (error) {
      this.props.showNotification('Failed to create PDF. Verity all fillins are filled in.')
      this.context.logger({'text': 'Failed to create PDF', 'error': error})
      console.log(error)
    }
  }

  handleFinalizeLetter = () => {
    this.setState({showFinalizationDialog: true})
  }

  handleFinalize = () => {
    this.setState({
      showFinalizationDialog: false,
      isFinalizeMode: true
    })

    // Generate text
    // generate text from section with fillins
    let finalizeText = ''

    // iterate over matches until each is replaced
    this.getTemplateSections().forEach(section => {
      let match
      let newSectionText = section.text
      while (match = newSectionText.match(/{([a-f0-9]+)}/)) { // eslint-disable-line no-cond-assign
        let fillinValue = _.get(this.state, ['dirtyLetterValues', match[1]], `[${_.get(section, ['fillins', match[1], 'properties', 'label'], 'missing')}]`)
        fillinValue = sanitizeHtml(fillinValue)
        newSectionText = newSectionText.replace(new RegExp('\\{' + match[1] + '\\}'), fillinValue)
      }
      finalizeText = finalizeText.length ? `${finalizeText}\n\n${newSectionText}` : newSectionText
    })
    this.setState({finalizeText: finalizeText})
  }

  handleFinalizeClose = () => {
    this.setState({showFinalizationDialog: false})
  }

  handleBackToTemplate = () => {
    this.setState({
      isFinalizeMode: false,
      finalizeText: ''
    })
  }

  getTemplateSections = () => {
    return _.get(this.props, ['template', 'sections'], []).reduce((sections, sectionId) => {
      const sectionObject = this.props.allSections[sectionId]
      if (sectionObject) {
        sections.push(sectionObject)
      } else {
        console.log(`Error missing section ${sectionId}`)
      }
      return sections
    }, [])
  }

  render () {
    const { classes, template } = this.props
    const templateSections = this.getTemplateSections()

    // Create an array of all fillins for the fillin widget
    const templateFillins = templateSections
      .map(section => section.fillins)
      .reduce((acc, sectionFillinObject) => {
        const fillinIds = Object.getOwnPropertyNames(sectionFillinObject)
        fillinIds.sort((a, b) => {
          const aOrder = _.get(sectionFillinObject, [a, 'order'], null)
          const bOrder = _.get(sectionFillinObject, [b, 'order'], null)
          if (aOrder !== null && bOrder !== null) {
            return Math.sign(aOrder - bOrder)
          } else {
            return 0
          }
        })
        fillinIds.forEach(fillinId => acc.push(sectionFillinObject[fillinId]))
        return acc
      }, [])

    return <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          {!this.state.isFinalizeMode && <div>
            <Paper className={classes.root}>
              <Link to='/search'>&#9664;&#xFE0E; Back to templates</Link>
              {templateSections.map((sectionObject, index) => (
                <LetterSectionPreview
                  key={`${sectionObject._id}${index}`}
                  text={sectionObject.text}
                  fillins={sectionObject.fillins}
                  letterValues={this.state.dirtyLetterValues}
                  handleSelectFillin={this.handleSelectFillin}
                  selectedFillin={this.state.selectedFillin}
                  className={classes.sectionPreview}
                />
              ))}
            </Paper></div>}
          {this.state.isFinalizeMode && <Paper className={classes.root}>
            <FinalizeLetterWidget
              text={this.state.finalizeText}
              backToTemplate={this.handleBackToTemplate}
            />
          </Paper>}
          {this.props.sectionNotesEnabled &&
            <Paper className={classes.root}>
              <Typography
                variant='subtitle1'>{`Writing letter based on template "${_.get(template, 'title', '???')}"`}</Typography>
              <TextField
                id='letterNotes'
                type='text'
                label='Personal notes about this letter'
                multiline
                fullWidth
                rows={3}
                rowsMax={10}
                value={this.state.dirtyLetterNotes}
                onChange={(e) => this.setState({dirtyLetterNotes: e.target.value})}
              />
            </Paper>
          }
        </Grid>
        <Grid item xs={12} sm={4} className={classes.sidebarGrid}>
          <div
            className={classNames({
              [classes.sidebar]: true
              //[classes.sidebar]: isWidthUp('sm', this.props.width),
              //[classes.smallSidebar]: isWidthDown('sm', this.props.width)
            })}
          >
            {!this.state.isFinalizeMode && <div className={classNames({
              [classes.xsmallSidebar]: isWidthDown('xs', this.props.width)
            })}>
              <LetterEditorFillinWidget
                fillins={templateFillins}
                selectedFillinKey={this.state.selectedFillin}
                letterValues={this.state.dirtyLetterValues}
                handleSelectFillin={this.handleSelectFillin}
                handleDeSelectFillin={this.handleDeSelectFillin}
                handleUpdateFillinValue={this.handleUpdateFillinValue}
              />
            </div>}
            <Button
              variant='contained'
              color='primary'
              size='small'
              fullWidth
              className={classes.letterActionButton}
              onClick={() => this.handleSaveLetter(false)}
            >
              <PictureAsPdf className={classes.buttonIcon} />
              Save PDF
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='small'
              fullWidth
              className={classes.letterActionButton}
              onClick={() => this.handleSaveLetter(true)}
            >
              <Print className={classes.buttonIcon} />
              Print Letter
            </Button>
            {!this.state.isFinalizeMode && <Tooltip title='Make any final changes to your letter.'>
              <Button
                variant='contained'
                color='primary'
                size='small'
                fullWidth
                className={classes.letterActionButton}
                onClick={() => this.handleFinalizeLetter()}
              >
                <Create className={classes.buttonIcon} />
                Customize Letter
              </Button>
            </Tooltip>}
          </div>
        </Grid>
      </Grid>
      <Prompt
        when={this.state.dirty}
        message='Are you sure you want to leave this page. You will lose your work.'
      />
      <Dialog
        open={this.state.showFinalizationDialog}
        onClose={this.handleFinalizeClose}
      >
        <DialogTitle><Favorite />Customize letter?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you ready to customize your letter? Customizations <strong>will not be saved</strong> if you go back to the template.
            Make sure you are done using the template editor.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleFinalize} color='primary'>
            Customize Letter
          </Button>
          <Button onClick={this.handleFinalizeClose} color='primary' autoFocus>
            Stay Here
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  sectionPreview: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingBottom: theme.spacing(2)
  },
  iconButton: {
    width: 30,
    height: 30
  },
  letterActionButton: {
    marginBottom: 12,
    justifyContent: 'flex-start'
  },
  buttonIcon: {
    marginRight: 10
  },
  sidebar: {
    //position: 'fixed',
    position: 'sticky',
    //top: 130,
    top: 20
    //width: '25%'
  },
  smallSidebar: {
    top: 90
  },
  xsmallSidebar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 1
  }
})

export default compose(
  withStyles(styles),
  withWidth(),
  connect(null, {showNotification})
)(LetterEditor)
