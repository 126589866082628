import React, { Component } from 'react'
import { Typography, Grid, Button, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TAGS } from '../helpers/Constants'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import CheckCircleOutline from '../components/icons/CheckCircleOutline'
import WritePencil from '../components/icons/WritePencil'
import classNames from 'classnames'

class WelcomePage extends Component {
  render () {
    const { classes } = this.props
    return (
      <div>

        <Grid container className={classes.gridContainer}>
          <Grid container className={classes.gridContainer}>
            <div className={classes.topBackground} />
            <Grid item xs={12} sm={5}>
              <Grid container className={classes.topGrid}>
                <Grid item>
                  <Typography variant='h2' className={classes.mainHead}>
                    Trying to<br />buy a home?
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1' gutterBottom className={classes.welcomeTypography}>Strengthen your offer with an offer letter<br />to the home seller!</Typography>
                  <Typography variant='subtitle1' gutterBottom className={classes.welcomeTypography}>Write an effective letter fast using the <strong>free</strong> <br /><strong>templates</strong> on Home Love Letter.</Typography>
                </Grid>
                <Grid item className={classes.buttonGrid}>
                  <Button variant='contained' color='secondary' className={classes.button} component={Link} to='/search'>
                    <WritePencil style={{ fontSize: 16, marginRight: 12 }} />
                    Start your letter now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12} sm={7}>
                <div className={classes.armchairHeart} />
              </Grid>
            </Hidden>
          </Grid>
          <Grid container className={classes.gridContainer} spacing={3}>
            <Grid item>
              <Typography variant='h4'>
                What is a <span className={classes.loveLetterSpan}>love letter?</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' gutterBottom className={classes.welcomeTypography}>In home buying, a “love letter,” also known as an offer letter for house hunters, is your chance to spell out why you are drawn to a property, your motivations, and your intentions in a personal way. It demonstrates your seriousness about making the deal happen—which is exactly what sellers want to see.</Typography>
              <Typography variant='subtitle1' gutterBottom className={classes.welcomeTypography}>Your real estate agent or Realtor will advocate for you during the home buying process, but a offer letter to the home seller is your chance to pitch your offer directly. In a competitive market, making that pitch, and making it fast, can make all the difference.</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridContainer} spacing={3}>
            <Grid item>
              <Typography variant='h4'>
                Why should you use a <span className={classes.loveLetterSpan}>offer letter?</span>
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction='row' alignItems='stretch' justifyContent='center' spacing={2} className={classes.gridRow}>
                <Grid item xs={12} sm={4} className={classes.prosItem}>
                  <CheckCircleOutline color='secondary' className={classes.prosIcon} />
                  <span>Demonstrate commitment to the deal</span>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.prosItem}>
                  <CheckCircleOutline color='secondary' className={classes.prosIcon} />
                  <p>Compete without cash</p>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.prosItem}>
                  <CheckCircleOutline color='secondary' className={classes.prosIcon} />
                  <p>Make your offer one they can’t ignore</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <p>Sellers want to make sure that Buyers are committed to the purchase and unlikely to get cold feet. They also want to know that their home will be in good hands. A love letter can put the Seller’s mind at ease, and it can make your offer stand out among the rest. </p>
              <p>Love letters, or offer letters to house sellers, also make the home-buying process more personal, because you can tell a unique story that resonates with Sellers. After all, there are real people behind the sale!</p>
              <p>Perhaps best of all, writing a letter is free. (And so are all of the templates on Home Love Letter&trade;!) So you can strengthen your home offer without additional costs.</p>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary' className={classes.button} component={Link} to='/search'>
                Get Started Now
              </Button>
            </Grid>
          </Grid>
          <div>
            <div className={classes.ovalBackground} />
            <Grid container className={classNames(classes.gridContainer, classes.rightBox)}>
              <Grid item>
                <Typography variant='h4' className={classes.darkBackground}>How does this site help?</Typography>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1' gutterBottom className={classes.welcomeTypography}>
                  Even though the love letter adds a personal touch to your real estate offer, at its core, this is a business letter, like a cover letter during the job search. So it’s important to touch on the right details.
                </Typography>
                <Typography variant='subtitle1' gutterBottom className={classes.welcomeTypography}>
                  The templates on Home Love Letter&trade; will walk you through all the details you should consider. They are inspired by effective real-world offer letters that won clients their homes.
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.gridContainer} spacing={3}>
              <Grid item xs={12} sm={6}>
                <Grid container className={classNames(classes.gridContainer, classes.wideBottom)}>
                  <Grid item>
                    <Typography variant='h4' className={classes.darkBackground}>Here’s how it works:</Typography>
                  </Grid>
                  <Grid item>
                    <ol>
                      <li>Choose a template matching your situation</li>
                      <li>Customize it with your home buying details</li>
                      <li>Download or print your unique letter</li>
                      <li>Send it along with your offer</li>
                    </ol>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.header}>
                      You’ll find offer letter templates for:{' '}
                      {TAGS.map((tag, i) => (
                        <span key={tag}>
                          <Link to={`/search/${tag}`}>{tag}</Link>{i < TAGS.length - 1 && ', '}
                        </span>
                      ))}... and more
                    </Typography>
                  </Grid>
                  <Grid>
                    <Button variant='contained' color='secondary' className={classes.button} component={Link} to='/search'>
                      <WritePencil style={{ fontSize: 16, marginRight: 12 }} />
                      Start your letter now
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <Typography variant='h4'>Want more<br />home buying tips?</Typography>
                    <Grid container className={classNames(classes.gridContainer, classes.headshotBox)}>
                      <Grid item>
                        <div className={classes.headshot} />
                      </Grid>
                      <Grid item>
                        <p><em>“Without exaggeration, Kate is the real estate agent you dare to dream you might find.”</em></p>
                        <Button variant='contained' color='secondary' className={classes.button} href='https://katebostonrealestate.com'>
                          Let Kate Ziegler, Realtor help
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden xsDown>
                <Grid item xs={12} sm={6}>
                  <div className={classes.homeCircleForeground} />
                  <div className={classes.homeCircleBackground} />
                </Grid>
              </Hidden>
            </Grid>
          </div>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  chip: {
    margin: 4
  },
  header: {
    paddingBottom: theme.spacing(2)
  },
  welcomeTypography: {
    marginBottom: '1em'
  },
  image: {
    boxShadow: '4px 4px 8px #aaa',
    width: '100%'
  },
  mainHead: {
    fontFamily: '"Roboto Condensed", sans-serif',
    lineHeight: '.9em',
    marginBottom: '0.5em',
    textTransform: 'uppercase',
    fontSize: '4em'
  },
  topGrid: {
    marginBottom: '2em'
  },
  wideBottom: {
    marginBottom: '5em !important'
  },
  ovalBackground: {
    backgroundImage: 'url("/oval_background.png")',
    height: '100%',
    position: 'absolute',
    left: 0,
    backgroundPosition: 'center top',
    backgroundSize: '175vw 90%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    marginTop: '-40px'
  },
  topBackground: {
    backgroundImage: 'url("/top_grid_bg.png")',
    height: '100%',
    position: 'absolute',
    left: 0,
    backgroundPosition: 'center top',
    backgroundSize: '100vw 100%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    marginTop: '-250px'
  },
  darkBackground: {
    textShadow: '2px 2px #ccc',
    color: 'white'
  },
  sectionHeaders: {
    paddingTop: theme.spacing(1)
  },
  topButtonGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonGrid: {
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  prosList: {
    fontSize: '1.2em'
  },
  prosItem: {
    display: 'flex',
    alignItems: 'center'
  },
  prosIcon: {
    fontSize: 60,
    paddingRight: 12
  },
  steps: {
    fontSize: '1.4em'
  },
  quoteGrid: {
    display: 'flex'
  },
  calloutQuote: {
    position: 'relative',
    fontStyle: 'italic',
    padding: theme.spacing(2),
    zIndex: 1,
    '&::before': {
      content: '"“"',
      fontFamily: 'Courgette',
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: '8em',
      color: '#eee',
      lineHeight: '1.2em',
      zIndex: -1,
      marginLeft: '-.2em',
      marginTop: '-.1em'
    }
  },
  loveLetterSpan: {
    // this will be for a callout color.
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > div': {
      width: '70%',
      '& > *': {
        textAlign: 'right'
      }
    }
  },
  headshotBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  headshot: {
    width: 100,
    height: 100,
    borderRadius: 50,
    background: 'url("/kz_headshot.jpg")',
    backgroundSize: 100,
    boxShadow: '8px 8px 16px -2px rgba(0,0,0,0.42)',
    marginRight: 24
  },
  homeCircleBackground: {
    backgroundImage: 'url("/house_background.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    clipPath: 'circle(200px at center)',
    '-webkitClipPath': 'circle(200px at center)'
  },
  homeCircleForeground: {
    backgroundImage: 'url("/home_circle_foreground.png")',
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    float: 'right',
    height: '100%',
    position: 'relative',
    width: '100%',
    zIndex: 1
  },
  armchairHeart: {
    backgroundImage: 'url("/armchair_woman_heart.png")',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '120%',
    width: '100%'
  },
  gridContainer: {
    marginBottom: '3em'
  },
  gridItem: {
    marginBottom: '2em'
  }
})

export default compose(
  withStyles(styles),
  connect()
)(WelcomePage)
