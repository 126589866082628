import { connect } from 'react-redux'
import LoginForm from '../components/LoginForm'
import { signOut, validateUser } from '../actions/user'

const mapStateToProps = state => {
  const {email, isSignedIn} = state.user
  return {
    email,
    isSignedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    validateUser: (email, password, history) => {
      dispatch(validateUser(email, password, history))
    },
    signOut: () => { dispatch(signOut()) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)
