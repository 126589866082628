import React, { Component } from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import {
  Button,
  FormControl,
  Paper,
  Typography,
  withWidth,
  Dialog,
  Slide
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons'
import LetterEditorFillinText from './LetterEditorFillinText'
import LetterEditorFillinDropdown from './LetterEditorFillinDropdown'
import { FILLIN } from '../helpers/Constants'
import { HotKeys } from 'react-hotkeys'
import { compose } from 'redux'
import { isWidthDown } from '@material-ui/core/withWidth'
import classNames from 'classnames'
import { BrowserView, MobileView } from 'react-device-detect'

class LetterEditorFillinWidget extends Component {
  static propTypes = {
    fillins: PropTypes.array,
    selectedFillinKey: PropTypes.string,
    letterValues: PropTypes.object,
    handleSelectFillin: PropTypes.func,
    handleDeSelectFillin: PropTypes.func,
    handleUpdateFillinValue: PropTypes.func
  }
  static defaultProps = {
    fillins: [],
    selectedFillinKey: null
  }

  handleBack = () => {
    const { fillins, selectedFillinKey } = this.props
    const currentIndex = fillins.findIndex(fillin => selectedFillinKey === fillin.key)
    if (currentIndex > 0) {
      this.props.handleSelectFillin(fillins[currentIndex - 1].key)
    }
  }

  handleNext = () => {
    const { fillins, selectedFillinKey } = this.props
    const currentIndex = fillins.findIndex(fillin => selectedFillinKey === fillin.key)
    if (currentIndex >= 0 && currentIndex < fillins.length - 1) {
      this.props.handleSelectFillin(fillins[currentIndex + 1].key)
    } if (currentIndex === -1) {
      this.props.handleSelectFillin(fillins[0].key)
    }
  }

  fillinWidget = (fillin, value) => {
    const fillinTypes = {
      [FILLIN.TEXT]: LetterEditorFillinText,
      [FILLIN.DROPDOWN]: LetterEditorFillinDropdown
    }

    if (fillinTypes.hasOwnProperty(fillin.type)) {
      const FillinComponent = fillinTypes[fillin.type]
      return <FillinComponent
        properties={fillin.properties}
        value={value}
        handleUpdate={value => this.props.handleUpdateFillinValue(fillin.key, value)}
      />
    } else if (typeof fillin.type === 'undefined' || fillin.type === null) {
      return <p>Selected fillin.</p>
    } else {
      return <p>Fillin type not found!</p>
    }
  }

  keyMap = {
    'advanceFillin': 'tab',
    'reverseFillin': 'shift+tab'
  };

  keyMapHandlers = {
    advanceFillin: e => {
      e.preventDefault()
      this.handleNext()
    },
    reverseFillin: e => {
      e.preventDefault()
      this.handleBack()
    }
  }

  Transition = props => {
    return <Slide direction='down' {...props} />
  }

  render () {
    const { classes, fillins, selectedFillinKey, letterValues } = this.props
    const fillinObject = fillins.find(fillin => fillin.key === selectedFillinKey) || {}
    return (
      <HotKeys keyMap={this.keyMap} handlers={this.keyMapHandlers} focused>
        <BrowserView>
          <Paper className={classNames(classes.root, {
            [classes.smallRoot]: isWidthDown('xs', this.props.width)
          })}>
            <div className={classes.promptGrid}>
              <p className={classes.formPrompt}>{_.get(fillinObject, ['properties', 'label'], '')}</p>
              <p className={classes.formTip}>{_.get(fillinObject, ['properties', 'helperText'], '')}</p>
            </div>
            {this.fillinWidget(fillinObject, _.get(letterValues, selectedFillinKey, ''))}
            <div className={classes.fillinSteppers}>
              <Button
                size='small'
                onClick={this.handleBack}
                disabled={fillins.findIndex(fillin => selectedFillinKey === fillin.key) <= 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
              <Button
                size='small'
                onClick={this.handleNext}
                disabled={fillins.findIndex(fillin => selectedFillinKey === fillin.key) >= fillins.length - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            </div>
          </Paper>
        </BrowserView>
        <MobileView>
          <Dialog
            fullScreen
            open={!!selectedFillinKey}
            onClose={this.props.handleDeSelectFillin}
            TransitionComponent={this.Transition}
          >
            <Paper className={classNames(classes.root, {
              [classes.smallRoot]: isWidthDown('sm', this.props.width)
            })}>
              <div className={classes.promptGrid}>
                <p className={classes.formPrompt}>{_.get(fillinObject, ['properties', 'label'], '')}</p>
                <p className={classes.formTip}>{_.get(fillinObject, ['properties', 'helperText'], '')}</p>
              </div>
              {this.fillinWidget(fillinObject, _.get(letterValues, selectedFillinKey, ''))}
              <div className={classes.submitButtonContainer}>
                <Button
                  variant='contained'
                  onClick={this.props.handleDeSelectFillin}
                >
                  Done
                </Button>
              </div>
              <div className={classes.fillinSteppers}>
                <Button
                  size='small'
                  onClick={this.handleBack}
                  disabled={fillins.findIndex(fillin => selectedFillinKey === fillin.key) <= 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
                <Button
                  size='small'
                  onClick={this.handleNext}
                  disabled={fillins.findIndex(fillin => selectedFillinKey === fillin.key) >= fillins.length - 1}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              </div>
            </Paper>
          </Dialog>
        </MobileView>
      </HotKeys>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  smallRoot: {
    marginBottom: 0,
    textAlign: 'left'
  },

  iconButton: {
    width: 30,
    height: 30
  },
  fillinSteppers: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  promptGrid: {
    width: '100%',
    textAlign: 'left'
  },
  formPrompt: {
    fontWeight: 'bold',
    width: '100%'
  },
  formTip: {
    fontSize: 'smaller',
    width: '100%'
  },
  submitButtonContainer: {
    textAlign: 'left',
    width: '100%',
    marginTop: '1em',
    marginBottom: '1em'
  },
  fillinDropdown: {
  }
})

export default compose(
  withStyles(styles),
  withWidth()
)(LetterEditorFillinWidget)
