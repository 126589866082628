import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SnackBar from '@material-ui/core/Snackbar'
import withRouter from "../helpers/withRouter";
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'

class LoginForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    isSignedIn: PropTypes.bool,
    validateUser: PropTypes.func,
    signOut: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  }

  static defaultProps = {
    email: '',
    isSignedIn: false,
    validateUser: () => {},
    signOut: () => {},
    location: {},
  }

  handleLoginSubmit = () => {
    this.props.validateUser(this.state.email, this.state.password, this.props.history)
  }

  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      notificationOpen: false,
      notificationMessage: '',
      doRedirect: false,
      redirect: (props.location && props.location.state && props.location.state.redirect ? props.location.state.redirect : '/')
    }
  }

  render () {
    const { classes } = this.props
    return (this.props.isSignedIn
      ? <Paper className={classes.root}>
        <p>You are logged in as {this.props.email}. <a className='link' onClick={this.props.signOut}>Sign out.</a></p>
      </Paper>
      : <Paper className={classes.root}>
        <TextField
          id='email'
          label='Email'
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
        /><br />
        <TextField
          id='password'
          label='Password'
          type='password'
          value={this.state.password}
          onChange={(e) => this.setState({ password: e.target.value })}
        /><br />
        <Button
          variant='contained'
          onClick={this.handleLoginSubmit}
          className={classes.submit}
        >
          Login
        </Button>
        <SnackBar
          open={this.state.notificationOpen}
          message={this.state.notificationMessage}
        />
        {/*{this.state.doRedirect && <Redirect to={this.state.redirect} />}*/}
      </Paper>)
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  submit: {
    marginTop: 16
  }
})

export default compose(
  withRouter,
  withStyles(styles)
)(LoginForm)
