import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { CONSTANTS, ENVIRONMENTS } from '../helpers/Constants'

class ErrorBoundary extends Component {
  static contextTypes = {
    logger: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch (error, info) {
    this.setState({hasError: true})

    if (CONSTANTS.ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
      this.context.logger({'error': error, 'info': info})
    } else {
      console.log(error, info)
    }
  }

  render () {
    if (this.state.hasError) {
      return <Paper className={this.props.classes.root}>
        <h1>Oops! Something went wrong.</h1>
        <p>Hit back and try again</p>
      </Paper>
    }
    return this.props.children
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
})

export default withStyles(styles)(ErrorBoundary)
