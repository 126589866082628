import React, { Component } from 'react'
import { Snackbar } from '@material-ui/core'
import { connect } from 'react-redux'
import { clearNotification } from '../actions/global'

class NotificationContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      notification: null
    }
  }

  componentDidUpdate () {
    if (typeof this.props.notification === 'string' && this.state.open === false) {
      this.setState({
        open: true,
        notification: this.props.notification
      })
    }
  }

  handleOnClose = () => {
    this.props.clearNotification()
    this.setState({
      open: false,
      notification: null
    })
  }

  render () {
    return (
      <Snackbar
        open={this.state.open}
        message={this.state.notification}
        autoHideDuration={5000}
        onClose={this.handleOnClose}
      />
      // TODO: Replace with <Alert /> Component
      // https://mui.com/components/alert/
    )
  }
}

const mapStateToProps = state => {
  const { notification } = state.global
  return { notification }
}

const mapDispatchToProps = dispatch => ({
  clearNotification: () => dispatch(clearNotification())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer)
