import React, { Component } from 'react'
import * as _ from 'lodash'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import feathersClient from '../helpers/FeathersClient'
import { showNotification } from '../actions/global'
import { connect } from 'react-redux'

export const Roles = {
  USER: 'user',
  ADMIN: 'admin'
}

class SecureRoute extends Component {
  static propTypes = {
    role: PropTypes.string,
    redirectPath: PropTypes.string,
    path: PropTypes.string
  }

  static DefaultProps = {
    role: 0,
    redirectPath: null,
    path: '/'
  }

  constructor (props) {
    super(props)

    this.state = {
      authenticated: false,
      authenticationComplete: false
    }

    // Verify token is valid
    // feathersClient.passport.getJWT().then(token => {
    //   feathersClient.passport.verifyJWT(token)
    //     .then(payload => {
    //       this.setState({
    //         authenticated: true,
    //         authenticationComplete: true,
    //         roles: _.get(payload, 'roles', [])
    //       })
    //     })
    //     .catch(() => this.setState({
    //       authenticated: false,
    //       authenticationComplete: true
    //     }))
    // })

    feathersClient.reAuthenticate()
      .then(() => {
        feathersClient
          .get('authentication')
          .then(payload => {
            this.setState({
              authenticated: true,
              authenticationComplete: true,
              roles: payload?.user?.roles || []
            })
          })
          .catch(() => this.setState({
            authenticated: false,
            authenticationComplete: true
          }))
      })
  }

  render () {
    const { component: Component, role, ...rest } = this.props
    return <div>
      {this.state.authenticationComplete &&
      <Route {...rest} render={props => {
        if (this.state.authenticated && this.state.roles.includes(this.props.role)) {
          // If logged in with appropriate role
          return <Component {...props} />
        } else if (this.state.authenticated) {
          // If logged in, but without appropriate role
          this.props.showNotification('You do not have access to that page.')
          return <Redirect to={{ pathname: '/myletters', state: { redirect: this.props.path } }} />
        } else {
          // If not logged in
          this.props.showNotification('You do not have access to that page.')
          return <Redirect to={{ pathname: '/login', state: { redirect: this.props.path } }} />
        }
      }} />
      }
    </div>
  }
}

export default connect(null, {showNotification})(SecureRoute)
