import React, { Component } from 'react'
import LoginFormContainer from '../containers/LoginFormContainer'
import { Typography } from '@material-ui/core'

export default class LoginPage extends Component {
  showNotification = (message) => {
    this.setState({
      notificationOpen: true, notificationMessage: message
    }, () => { setTimeout(() => { this.setState({notificationOpen: false}) }, 4000) })
  }

  render = () => <div>
    <Typography variant='h4' gutterBottom>
      Login
    </Typography>
    <LoginFormContainer />
  </div>
}
