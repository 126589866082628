import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid, Paper,
  TextField, Typography, FormControlLabel, Switch
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TemplateEditorAllSections from './TemplateEditorAllSections'
import TemplateEditorTagWidget from './TemplateEditorTagWidget'
import TemplateEditorSectionsList from './TemplateEditorSectionsList'

class TemplateEditor extends Component {
  static propTypes = {
    template: PropTypes.object,
    commitTemplate: PropTypes.func,
    allSections: PropTypes.object
  }

  static defaultProps = {
    template: {},
    commitTemplate: () => {},
    allSections: {}
  }

  handleSaveTemplate = () => {
    this.props.commitTemplate({
      title: this.state.dirtyTitle,
      description: this.state.dirtyDescription,
      sections: this.state.dirtySections,
      tags: this.state.dirtyTags,
      published: this.state.dirtyPublished
    })
  }

  constructor (props) {
    super(props)
    this.state = {
      templateId: null,
      dirtyTitle: '',
      dirtyDescription: '',
      dirtySections: [],
      dirtyTags: [],
      dirtyPublished: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (
      prevState.templateId === null &&
      nextProps.template &&
      nextProps.template._id !== null
    ) {
      return {
        templateId: nextProps.template._id,
        dirtyTitle: nextProps.template.title,
        dirtyDescription: nextProps.template.description,
        dirtySections: nextProps.template.sections || [],
        dirtyTags: nextProps.template.tags || [],
        dirtyPublished: nextProps.template.published || false
      }
    } else {
      return null
    }
  }

  addSectionToTemplate = sectionKey => {
    this.setState({dirtySections: [...this.state.dirtySections, sectionKey]})
  }

  removeSectionFromTemplate = position => {
    this.setState({dirtSections: [...this.state.dirtySections.splice(position, 1)]})
  }

  moveSectionUp = position => {
    if (position === 0) return
    let newSections = [...this.state.dirtySections]
    const itemToMove = newSections.splice(position, 1)
    newSections.splice(position - 1, 0, itemToMove[0])
    this.setState({dirtySections: newSections})
  }

  moveSectionDown = position => {
    if (position >= this.state.dirtySections.length - 1) return
    let newSections = [...this.state.dirtySections]
    const itemToMove = newSections.splice(position, 1)
    newSections.splice(position + 1, 0, itemToMove[0])
    this.setState({dirtySections: newSections})
  }

  addTagToTemplate = tag => {
    if (!this.state.dirtyTags.find(dirtyTag => tag === dirtyTag) && tag.length > 0) {
      this.setState({dirtyTags: [...this.state.dirtyTags, tag]})
    }
  }

  removeTagFromTemplate = tag => {
    const index = this.state.dirtyTags.indexOf(tag)
    if (index >= 0) {
      const newTags = [...this.state.dirtyTags]
      newTags.splice(index, 1)
      this.setState({dirtyTags: newTags})
    }
  }

  render () {
    const { classes } = this.props
    return <div>
      {this.props.template
        ? <Typography variant='subtitle1'>Editing template {this.props.template.title}</Typography>
        : <Typography variant='subtitle1'>Creating new template</Typography>}
      <Grid container spacing={3} style={{width: '100%'}}>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            <TextField
              id='templateTitle'
              type='text'
              label='Title'
              fullWidth
              value={this.state.dirtyTitle}
              onChange={(e) => this.setState({dirtyTitle: e.target.value})}
            /> <br />
            <TextField
              id='templateDescription'
              type='text'
              label='Description'
              multiline
              fullWidth
              rows={3}
              rowsMax={10}
              value={this.state.dirtyDescription}
              onChange={(e) => this.setState({dirtyDescription: e.target.value})}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.dirtyPublished}
                  onChange={(e) => this.setState({dirtyPublished: e.target.checked})}
                  value='checkedA'
                />
              }
              label='Published'
            />
          </Paper>
          <TemplateEditorSectionsList
            dirtySections={this.state.dirtySections}
            allSections={this.props.allSections}
            removeSectionFromTemplate={this.removeSectionFromTemplate}
            moveSectionUp={this.moveSectionUp}
            moveSectionDown={this.moveSectionDown}
          />
          <TemplateEditorTagWidget
            dirtyTags={this.state.dirtyTags}
            addTagToTemplate={this.addTagToTemplate}
            removeTagFromTemplate={this.removeTagFromTemplate}
          />
          <Button
            variant='outlined'
            color='primary'
            onClick={this.handleSaveTemplate}
          >
            {this.props.template ? 'Save Changes' : 'Create Template'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TemplateEditorAllSections
            allSections={this.props.allSections}
            addSectionToTemplate={this.addSectionToTemplate}
          />
        </Grid>
      </Grid>
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  sectionPreview: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  iconButton: {
    width: 30,
    height: 30
  }
})

export default withStyles(styles)(TemplateEditor)
