import React, { Component } from 'react'

class DoLoveLettersWork extends Component {
  render () {
    return (
      <div>
        <p>In recent years in hot markets, “love letters” have become very popular among home buyers. The reason behind their popularity lies in the difficulties that many buyers face due to low inventory of homes for sale, especially for first-time buyers. This shortage fuels high demand and benefits sellers, resulting in high sale prices for their properties. Buyers flush with funds are at an advantage since they can leverage cash and close quickly; for buyers on a tight budget, offer letters written to the sellers can have an impact in convincing sellers to work with you, instead. There are plenty of sceptics who feel that love letters are overhyped and don’t deliver - maybe you’re even one of them! Let us explore whether real estate love letters help or hurt your chances: do offer letters work, or not?</p>
        <h3>Love letters do work for buyers</h3>
        <p>In 2017, one in three of the buyers Boston-based Realtor Kate Ziegler helped to purchase homes were able to convince sellers to accept their offer explicitly based on their home love letter. Offer letters are buyers’ key chance to speak directly to the seller, and to explain their plans and their offer and how it can work to the sellers’ advantage. In times when the market is hot and the inventory is short, love letters have become a useful tool in the hands of buyers who need to work creatively to maximize their budget. At worst, a love letter is ignored (heartbreaking!); at best, it makes all the difference in the world.</p>
        <h3>Money is not the only thing that matters</h3>
        <p>It is a difficult task for a buyer to get inside the mind of a seller. To an outsider, it appears that the only thing on the mind of a seller is money - and for some sellers, that may be the case. For others, even as every owner hopes to fetch a high price for their investment, there are other motivation at play inside the mind of the seller at the same time. A love letter from you as a buyer gives you an opportunity to explore these feelings and to play to your strengths. Some sellers will be motivated by a specific timeline, and your offer letter is a space for you to express your flexibility. You can strike a chord with the seller by praising features of their property and expressing your desire to live in the neighborhood. You might acknowledge work that you know will be necessary after closing, to make it clear that you won’t get cold feet after inspection. The efficacy of your love letter depends upon how well you have been able to appeal to the motivations of the seller.</p>
        <h3>Reassure owner that you will take good care of his house</h3>
        <p>Many sellers find a strong emotional attachment with the home in which they have lived, even if they were not there for very long. The more fond the memories, the more the seller will want to believe that the person who buys the property also loves it like they do, and intends to cherish and maintain it. This is where your love letter does the trick! A real estate love letter is your opportunity to convey your feelings to the owner of the property. Tell them what you appreciate most about the home as it is now, about your engagement in the neighborhood, and about your intent to love and care for their prized rose bushes for years to come.</p>
        <p>In truth, a love letter on its own is not enough to sway the opinion of most sellers; competitive offer terms and timelines combined with a strong offer price are key pieces of the puzzle that cannot be overlooked in this market. That said, for many sellers reviewing multiple offers on a single property, it is your love letter that helps you stand out and gives your offer the best possible chance of being the winning bid.</p>
      </div>
    )
  }
}

export default DoLoveLettersWork
