import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import TemplateEditor from '../components/TemplateEditor'
import { connect } from 'react-redux'
import { fetchSingleTemplate, newTemplate, setCurrentTemplate, editTemplate } from '../actions/template'
import { compose } from 'redux'
import { fetchAllSections } from '../actions/section'

const styles = {
  dense: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

class TemplatePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentTemplateObject: null
    }

    if (props.match.params.id) {
      props.fetchSingleTemplate(props.match.params.id)
    }

    props.fetchAllSections()
  }

  componentDidUpdate (prevProps) {
    // Wait until template and cache are loaded, and only load into state once
    if (typeof this.props.templateCache === 'object' &&
      this.props.templateCache[this.props.currentTemplate] &&
      this.state.currentTemplateObject === null
    ) {
      this.setState({currentTemplateObject: this.props.templateCache[this.props.currentTemplate]})
    }

    // Or, if the page had loaded a new template
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({currentTemplateObject: this.props.templateCache[this.props.match.params.id]})
    }
  }

  componentWillUnmount () {
    this.props.clearCurrentTemplate()
  }

  showFetchingError = () => (
    !this.props.isFetchingSingleTemplate && this.props.fetchSingleTemplateStatus === false
  )

  showNewTemplate = () => (
    !this.props.isFetchingSingleTemplate && !this.props.currentTemplate
  )

  showTemplate = () => (
    !this.props.isFetchingSingleTemplate && this.props.currentTemplate
  )

  currentTemplate = () => this.props.templateCache[this.props.currentTemplate]

  render = () =>
    <div>
      <Link to='/admin/templates'>&#9666; All Templates</Link>
      <h2>TEMPLATE PAGE</h2>
      {this.showFetchingError() && <p>Error! Could not load template.</p>}
      {this.showNewTemplate() &&
      <TemplateEditor
        commitTemplate={this.props.newTemplate}
        allSections={this.props.sectionCache}
      />}
      {this.showTemplate() &&
      <TemplateEditor
        template={this.state.currentTemplateObject}
        commitTemplate={template => this.props.editTemplate(this.props.currentTemplate, template)}
        allSections={this.props.sectionCache}
      />}
    </div>
}

const mapStateToProps = state => {
  const {
    isFetchingSingleTemplate,
    fetchSingleTemplateStatus,
    currentTemplate,
    templateCache
  } = state.template
  const {sectionCache} = state.section
  return {
    isFetchingSingleTemplate,
    fetchSingleTemplateStatus,
    currentTemplate,
    templateCache,
    sectionCache
  }
}

const mapDispatchToProps = dispatch => ({
  newTemplate: template => dispatch(newTemplate(template)),
  editTemplate: (id, template) => dispatch(editTemplate(id, template)),
  fetchSingleTemplate: id => dispatch(fetchSingleTemplate(id)),
  fetchAllSections: () => dispatch(fetchAllSections()),
  clearCurrentTemplate: () => dispatch(setCurrentTemplate(null))
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplatePage)
