import React, { Component } from 'react'
import { TAGS } from '../helpers/Constants'
import { Typography, Chip, TextField, Button, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class TemplateEditorTagWidget extends Component {
  constructor (props) {
    super(props)
    this.state = {customTag: ''}
  }

  addCustomTag = () => {
    this.props.addTagToTemplate(this.state.customTag)
    this.setState({customTag: ''})
  }

  render () {
    const { classes, dirtyTags, removeTagFromTemplate, addTagToTemplate } = this.props
    return (
      <Paper className={classes.root}>
        <Typography variant='h6' className={classes.header}>Tags</Typography>
        <Typography variant='subtitle1' className={classes.sectionHeaders}>Current Tags</Typography>
        {dirtyTags.map(tag => (
          <Chip
            key={tag}
            label={tag}
            className={classes.chip}
            onDelete={() => removeTagFromTemplate(tag)}
          />
        ))}
        {dirtyTags.length === 0 &&
          <p>No tags selected</p>
        }
        <Typography variant='subtitle1' className={classes.sectionHeaders}>All Tags</Typography>
        {TAGS.filter(tag => !dirtyTags.includes(tag)).map(tag => {
          return <Chip
            key={tag}
            label={tag}
            className={classes.chip}
            onClick={() => addTagToTemplate(tag)}
          />
        })}
        <Typography variant='subtitle1' className={classes.sectionHeaders}>Custom Tags</Typography>
        <TextField
          id='customTag'
          label='Custom Tag'
          margin='normal'
          value={this.state.customTag}
          className={classes.customTagText}
          onChange={e => this.setState({customTag: e.target.value})}
        />
        <Button
          variant='contained'
          size='small'
          color='secondary'
          aria-label='add tag'
          onClick={this.addCustomTag}
        >
          Add Custom Tag
        </Button>
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  chip: {
    margin: 4
  },
  header: {
    paddingBottom: theme.spacing(2)
  },
  sectionHeaders: {
    paddingTop: theme.spacing(1)
  },
  customTagText: {
    paddingRight: theme.spacing(1),
    margin: 0
  }
})

export default withStyles(styles)(TemplateEditorTagWidget)
