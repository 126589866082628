import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import {
  ListItem,
  ListItemSecondaryAction,
  Paper,
  List
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { fetchAllTemplates, deleteTemplate } from '../actions/template'
import { redirect } from '../actions/global'
import { Add, Delete, FormatAlignLeft } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const TemplateRow = ({templateData, handleDelete}) =>
  <ListItem
    button
    component={Link}
    to={`/admin/template/${templateData._id}`}
  >
    <ListItemAvatar>
      <Avatar>
        <FormatAlignLeft />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={templateData.title}
      secondary={templateData.description}
    />
    <ListItemSecondaryAction onClick={handleDelete}>
      <IconButton aria-label='Delete'>
        <Delete />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>

class TemplatesPage extends Component {
  constructor (props) {
    super(props)
    props.fetchAllTemplates()
  }
  handleNewTemplate = () => {
    this.props.redirect('/admin/template')
  }

  showFetchingError = () => (
    !this.props.isFetchingTemplates && this.props.fetchTemplatesStatus === false
  )

  showTemplate = () => (
    !this.props.isFetchingTemplates && this.props.fetchTemplatesStatus
  )

  render () {
    return (
      <div>
        <Typography variant='h6'>
          Letter Templates
        </Typography>
        <Link to='/admin/sections'>Edit Sections ></Link>
        {this.showFetchingError() && <p>Error, could not load templates: {this.props.fetchTemplatesMessage}</p>}
        {this.showTemplate() &&
        <Paper>
          <List dense>
            {Object.getOwnPropertyNames(this.props.templateCache).map(templateId => {
              const template = this.props.templateCache[templateId]
              return (
                <TemplateRow
                  key={template._id}
                  templateData={template}
                  handleDelete={() => this.props.deleteTemplate(template._id)}
                />)
            })}
          </List>
          <Fab color='primary' aria-label='add' onClick={this.handleNewTemplate}>
            <Add />
          </Fab>
        </Paper>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    isFetchingTemplates,
    fetchTemplatesStatus,
    fetchTemplatesMessage,
    templateCache} = state.template
  return {
    isFetchingTemplates,
    fetchTemplatesStatus,
    fetchTemplatesMessage,
    templateCache
  }
}

const mapDispatchToProps = dispatch => ({
  deleteTemplate: id => dispatch(deleteTemplate(id)),
  fetchAllTemplates: () => dispatch(fetchAllTemplates()),
  redirect: to => dispatch(redirect(to))
})

const styles = theme => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  dense: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplatesPage)
