import React, { Component } from 'react'
import { Paper, withWidth, Grid } from '@material-ui/core'
import { isWidthUp } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'
import { CONSTANTS } from '../helpers/Constants'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as _ from 'lodash'

class Sidebar extends Component {
  render () {
    const { classes, width } = this.props
    const showKZSidebar = _.get(this.props, ['userLocation', 'distance'], 1000) <= CONSTANTS.TERRITORY_MILES
    return (
      <Paper className={classes.root} style={{padding: 8}}>
        {isWidthUp('md', width) && <div>
          <h3>{showKZSidebar ? 'Hunting Hunting in Boston?' : 'Want more home buying tips?'}</h3>
          <p>Let <a href='http://katebostonrealestate.com' target='_blank' rel='noopener noreferrer'>Kate Ziegler,
            Realtor</a> help.</p>
          <a href='http://katebostonrealestate.com' target='_blank' rel='noopener noreferrer'>
            <img className={classes.headshot} src='/kz_headshot.jpg' alt='Kate Ziegler' />
            <p><em>“Without exaggeration, Kate is the real estate agent you dare to dream you might find.”</em></p>
          </a>
        </div>}
        {!isWidthUp('md', width) && <Grid container spacing={2}>
          <Grid item xs={6} sm={9} md={10}>
            <h3>{showKZSidebar ? 'Hunting Hunting in Boston?' : 'Want more home buying tips?'}</h3>
            <p>Let <a href='http://katebostonrealestate.com' target='_blank' rel='noopener noreferrer'>Kate Ziegler, Realtor</a> help.</p>
            <p><em>“Without exaggeration, Kate is the real estate agent you dare to dream you might find.”</em></p>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <a href='http://katebostonrealestate.com' target='_blank' rel='noopener noreferrer'>
              <img className={classes.headshot} src='/kz_headshot.jpg' alt='Kate Ziegler' />
            </a>
          </Grid>
        </Grid>}
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: 12
  },
  headshot: {
    width: '100%'
  }
})

const mapStateToProps = state => {
  const {
    userLocation
  } = state.global
  return {
    userLocation
  }
}

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps)
)(Sidebar)
