import { connect } from 'react-redux'
import LoginWidget from '../components/LoginWidget'
import { signOut } from '../actions/user'

const mapStateToProps = state => {
  const { email, isSignedIn } = state.user
  return {
    email,
    isSignedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => { dispatch(signOut()) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginWidget)
