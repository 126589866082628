import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as _ from 'lodash'
import { fetchSingleTemplate, setCurrentTemplate } from '../actions/template'
import { fetchAllSections } from '../actions/section'
import LetterEditor from '../components/LetterEditor'

class WritePage extends Component {
  constructor (props) {
    super(props)

    const templateId = _.get(props, ['match', 'params', 'templateId'], null)
    const letterId = _.get(props, ['match', 'params', 'letterId'], null)

    // If template id is set, this is a new template
    if (templateId) {
      props.fetchSingleTemplate(templateId)
      props.fetchAllSections() // TODO: for performance, only fetch the sections that will be used
      this.state = {
        badTemplateId: false,
        currentTemplateObject: null,
        letterValues: {}
      }
    } else {
      this.state = {
        badTemplateId: true,
        currentTemplateObject: null
      }
    }

    // If letter id is set, this is an existing letter
    if (letterId) {
      // TODO: Build load template functionality, including backend.
      // Load template
      // Load sections
      // Load letter
    }
  }

  componentDidUpdate (prevProps) {
    // Wait until template and cache are loaded, and only load into state once
    if (typeof this.props.templateCache === 'object' &&
      this.props.templateCache[this.props.currentTemplate] &&
      this.state.currentTemplateObject === null
    ) {
      this.setState({currentTemplateObject: this.props.templateCache[this.props.currentTemplate]})
    }

    // Or, if the page had loaded a new template
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({currentTemplateObject: this.props.templateCache[this.props.match.params.id]})
    }
  }

  componentWillUnmount () {
    this.props.clearCurrentTemplate()
  }

  showTemplate = () => (
    !this.props.isFetchingSingleTemplate &&
    !this.props.isFetchingSections &&
    this.state.currentTemplateObject
  )

  // currentTemplate = () => this.props.templateCache[this.props.currentTemplate]

  showFetchingError = () => (
    !this.props.isFetchingSingleTemplate && this.props.fetchSingleTemplateStatus === false
  )

  render = () => {
    return <div>
      {(this.state.badTemplateId || this.showFetchingError()) && <p>Error! Could not load template.</p>}
      {this.showTemplate() &&
      <LetterEditor
        template={this.state.currentTemplateObject}
        letterValues={{}}
        // commitLetter={template => this.props.editTemplate(this.props.currentTemplate, template)}
        allSections={this.props.sectionCache}
      />}
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  dense: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

const mapStateToProps = state => {
  const {
    isFetchingSingleTemplate,
    fetchSingleTemplateStatus,
    currentTemplate,
    templateCache
  } = state.template
  const {
    sectionCache,
    isFetchingSections
  } = state.section
  return {
    isFetchingSingleTemplate,
    fetchSingleTemplateStatus,
    currentTemplate,
    templateCache,
    sectionCache,
    isFetchingSections
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSingleTemplate: id => dispatch(fetchSingleTemplate(id)),
  fetchAllSections: () => dispatch(fetchAllSections()),
  clearCurrentTemplate: () => dispatch(setCurrentTemplate(null))
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(WritePage)
