import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Typography, Paper } from '@material-ui/core'
import { ARTICLES } from '../helpers/ArticleList'
import { formatDate } from '../helpers/HelperFunctions'

class ArticlePage extends Component {
  render () {
    const { classes } = this.props
    return <div>
      <Typography variant='h4' gutterBottom>Love Letter Articles</Typography>
      {Object.getOwnPropertyNames(ARTICLES).map(article => <div key={article}>
        <Paper className={classes.root}>
          <Typography variant='h5'><Link to={`/article/${article}`}>{ARTICLES[article].title}</Link></Typography>
          <p>{formatDate(ARTICLES[article].date)}</p>
          <p className={classes.description}>{ARTICLES[article].description}</p>
        </Paper>
      </div>)}
    </div>
  }
}

const styles = theme => ({
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
})

export default withStyles(styles)(ArticlePage)
