import React, { Component } from 'react'
import { SvgIcon } from '@material-ui/core'

class WritePencil extends Component {
  render () {
    return <SvgIcon {...this.props} viewBox='0 0 100 84.705'>
      <g>
        <path d='M76.829,70.955c0,2.393-1.985,4.337-4.427,4.337H14.03c-2.442,0-4.427-1.944-4.427-4.337V13.676   c0-2.393,1.985-4.265,4.427-4.265h55.146L81.03,0.146C80.912,0.14,80.852,0,80.73,0H5.702C2.562,0,0,2.438,0,5.514v73.602   c0,3.076,2.562,5.589,5.702,5.589H80.73c3.14,0,5.703-2.513,5.703-5.589V37.793l-9.604,7.5V70.955z' />
        <polygon points='90.148,1.189 85.848,5.216 95.699,15.32 100,11.293  ' />
        <polygon points='41.027,46.776 50.827,56.825 91.648,18.601 81.849,8.551  ' />
        <polygon points='32.103,64.431 42.145,64.656 42.256,64.659 45.812,61.33 35.997,51.262 35.997,51.262    32.384,54.646  ' />
      </g>
    </SvgIcon>
  }
}

export default WritePencil
