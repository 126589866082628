export const ENVIRONMENTS = {
  PRODUCTION: 'prod',
  DEVELOPMENT: 'dev'
}

// const isProd = window.location.hostname === 'homeloveletter.com' || window.location.hostname === 'www.homeloveletter.com'
const isProd = process.env.NODE_ENV === 'production'

export const CONSTANTS = {
  API_URL: `${isProd ? 'https://api.homeloveletter.com' : 'http://localhost:3030'}`,
  ENVIRONMENT: isProd ? ENVIRONMENTS.PRODUCTION : ENVIRONMENTS.DEVELOPMENT,
  TERRITORY_MILES: 10
}

// Fillin Type Constants
export const FILLIN = {
  TEXT: 0,
  DROPDOWN: 1
}

// Fillin Type looup
export const FILLIN_LOOKUP = {
  0: 'Text',
  1: 'Dropdown'
}

export const TAGS = [
  'Apartment',
  'Single Family',
  'Condo',
  'Situation',
  'Veteran',
  'Family',
  'Pets'
]
