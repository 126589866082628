import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles, withWidth, Grid, Typography, Paper } from '@material-ui/core'
import { CONSTANTS } from '../helpers/Constants'
import * as _ from 'lodash'
import classNames from 'classnames'

class HouseHuntingPage extends Component {
  static propTypes = {}

  static defaultProps = {}

  // show confirm location
  // show ask location
  // show KZ content
  // show random content
  // show form

  constructor (props) {
    super(props)
    this.surveyRef = React.createRef()
  }

  componentDidMount () {
    let s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.id = 'smcx-sdk'
    s.src = 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1p4ItOhdodgRnvy0Eew5QQvH51J7ibOoTAy2HvkKgP0.js'
    this.surveyRef.current.appendChild(s)
  }

  showConfirmLocation = () => {
    // do we have location through geo or ip
  }
  showAskLocation = () => {}
  showKZContent = () => {
    // We know their location, and it's INSIDE KZ territory
    return _.isNumber(_.get(this.props, ['userLocation', 'distance'])) &&
      _.get(this.props, ['userLocation', 'distance'], 1000) <= CONSTANTS.TERRITORY_MILES
  }
  showGenericContent = () => {
    // We know their location, and it's OUTSIDE KZ territory
    return _.isNumber(_.get(this.props, ['userLocation', 'distance'])) &&
      _.get(this.props, ['userLocation', 'distance'], 1000) > CONSTANTS.TERRITORY_MILES
  }

  render () {
    const { classes, userLocation } = this.props
    return (
      <div>
        {/*{this.showConfirmLocation() && <p>Confirm location.</p>}*/}
        {/*{this.showAskLocation() && <p>Ask location.</p>}*/}
        {this.showKZContent() && <div>
          <Typography variant='h4'>Start house-hunting</Typography>
          <Paper className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3} md={2}>
                <a href='http://katebostonrealestate.com' target='_blank' rel='noopener noreferrer'>
                  <img className={classes.headshot} src='/kz_headshot.jpg' alt='Kate Ziegler' />
                </a>
              </Grid>
              <Grid item xs sm md>
                <h3>Buying or selling around Boston?</h3>
                <p>Let <a href='http://katebostonrealestate.com' target='_blank' rel='noopener noreferrer'>Kate Ziegler, Realtor</a> help.</p>
                <p><em>“Without exaggeration, Kate is the real estate agent you dare to dream you might find.”</em></p>
                <p>Kate Ziegler is a Boston-based Realtor with Arborview Realty, as well as a small business owner, designer, speaker, and property manager. She works with clients throughout Greater Boston, and is tireless in helping buyers find homes that fit their unique needs and budgets in a competitive market, without leaving the communities they love.</p>
              </Grid>
            </Grid>
          </Paper>
        </div>}
        {this.showGenericContent() && <Paper className={classes.root}>
          <p> Whatever stage you're at in your house-hunting journey, we can help.
            We have a network of agents across the country.
            Enter your details below so we can connect you with someone in your area.</p>
        </Paper>}
        <Paper className={classes.root}>
          {this.showKZContent() && <Typography gutterBottom variant='h5'>Enter your details below so we can start helping you with your search.</Typography>}
          {this.showGenericContent() && <Typography gutterBottom variant='h5'> Whatever stage you're at in your house-hunting journey, we can help. We have a network of agents across the country. Enter your details below so we can connect you with someone in your area.</Typography>}
          <div ref={this.surveyRef} className={classes.formContainer}></div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { userLocation } = state.global
  return {
    userLocation
  }
}

const mapDispatchToProps = dispatch => ({})

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  formContainer: {
    maxWidth: '700px',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  headshot: {
    width: '100%'
  }
})

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps)
)(HouseHuntingPage)
