import feathersClient from '../helpers/FeathersClient'
import {
  FETCH_SECTIONS_BEGIN,
  FETCH_SECTIONS_SUCCESS,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_ABORT,
  INVALIDATE_SECTION_CACHE,
  SET_CURRENT_SECTION,
  FETCH_SINGLE_SECTION_BEGIN,
  FETCH_SINGLE_SECTION_SUCCESS,
  FETCH_SINGLE_SECTION_ABORT,
  FETCH_SINGLE_SECTION_FAILURE,
  DELETE_SECTION_BEGIN,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
  NEW_SECTION_BEGIN,
  NEW_SECTION_SUCCESS,
  NEW_SECTION_FAILURE,
  EDIT_SECTION_BEGIN,
  EDIT_SECTION_SUCCESS,
  EDIT_SECTION_FAILURE
} from './actionTypes'
import { redirect, showNotification } from './global'
import * as _ from 'lodash'

export const fetchSectionsBegin = () => ({
  type: FETCH_SECTIONS_BEGIN
})

export const fetchSectionsSuccess = sections => ({
  type: FETCH_SECTIONS_SUCCESS,
  sections
})

export const fetchSectionsFailure = errorMessage => ({
  type: FETCH_SECTIONS_FAILURE,
  errorMessage
})

export const fetchSectionsAbort = () => ({
  type: FETCH_SECTIONS_ABORT
})

export const fetchSingleSectionBegin = () => ({
  type: FETCH_SINGLE_SECTION_BEGIN
})

export const fetchSingleSectionSuccess = section => ({
  type: FETCH_SINGLE_SECTION_SUCCESS,
  section
})

export const fetchSingleSectionAbort = id => ({
  type: FETCH_SINGLE_SECTION_ABORT,
  id
})

export const fetchSingleSectionFailure = () => ({
  type: FETCH_SINGLE_SECTION_FAILURE
})

export const invalidateSectionCache = () => ({
  type: INVALIDATE_SECTION_CACHE
})

export const setCurrentSection = id => ({
  type: SET_CURRENT_SECTION,
  id
})

export const deleteSectionBegin = id => ({
  type: DELETE_SECTION_BEGIN,
  id
})

export const deleteSectionSuccess = id => ({
  type: DELETE_SECTION_SUCCESS,
  id
})

export const deleteSectionFailure = error => ({
  type: DELETE_SECTION_FAILURE,
  error
})

export const newSectionBegin = () => ({
  type: NEW_SECTION_BEGIN
})

export const newSectionSuccess = section => ({
  type: NEW_SECTION_SUCCESS,
  section
})

export const newSectionFailure = () => ({
  type: NEW_SECTION_FAILURE
})

export const editSectionBegin = () => ({
  type: EDIT_SECTION_BEGIN
})

export const editSectionSuccess = section => ({
  type: EDIT_SECTION_SUCCESS,
  section
})

export const editSectionFailure = () => ({
  type: EDIT_SECTION_FAILURE
})

//   ASYNC   //

// Async action for fetching all sections
const shouldUpdateSectionCache = state => {
  const timestamp = state.section.sectionCacheTimestamp
  return !timestamp || timestamp < (Date.now() - (1000 * 60 * 60))
} // 1 Hour TTL

export const fetchAllSections = () => {
  return (dispatch, getState) => {
    dispatch(fetchSectionsBegin())
    if (shouldUpdateSectionCache(getState())) {
      return feathersClient.service('sections').find({limit: 100})
        .then(response => {
          // parse sections here
          let allSections = {}
          response.data.forEach(section => {
            allSections[section._id] = section
          })
          dispatch(fetchSectionsSuccess(allSections))
        })
        .catch(error => {
          dispatch(fetchSectionsFailure(error))
        })
    } else {
      dispatch(fetchSectionsAbort())
    }
  }
}

export const fetchAndSetSection = id => {
  return async (dispatch) => {
    dispatch(fetchSingleSection(id))
      .then(dispatch(setCurrentSection(id)))
  }
}

// Async action to fetch one section, if needed
export const fetchSingleSection = id => {
  return async (dispatch, getState) => {
    // Set fetching section to true
    dispatch(fetchSingleSectionBegin())
    const state = getState()
    if (state.section.sectionCache[id]) {
      // If section exists in cache, set it as current
      dispatch(fetchSingleSectionAbort(id))
    } else {
      // Otherwise try to get it from the server
      return feathersClient.service('sections').get(id)
        .then(response => {
          dispatch(fetchSingleSectionSuccess(response))
        })
        .catch(() => dispatch(fetchSingleSectionFailure()))
    }
  }
}

export const refreshSectionCache = () => {
  return dispatch => {
    dispatch(invalidateSectionCache())
    dispatch(fetchAllSections())
  }
}

// Async actions for delete section
export const deleteSection = id => {
  return async (dispatch) => {
    // Set fetching section to true
    dispatch(deleteSectionBegin())
    feathersClient.service('sections').remove(id)
      .then(response => {
        dispatch(showNotification('Section deleted.'))
        dispatch(deleteSectionSuccess(id))
      })
      .catch(error => {
        dispatch(deleteSectionFailure(error))
      })
  }
}

// Async actions for creating new section
export const newSection = section => {
  return async (dispatch) => {
    // Set fetching section to true
    dispatch(newSectionBegin())
    feathersClient.service('sections').create(section)
      .then(response => {
        dispatch(newSectionSuccess(response))
        dispatch(showNotification('Added new section!'))
      })
      .catch(error => {
        dispatch(showNotification(`Adding new section failed. ${error}`))
        dispatch(newSectionFailure(error))
      })
  }
}

export const newSectionAndRedirect = section => {
  return async (dispatch) => {
    // Set fetching section to true
    dispatch(newSection(section))
      .then(response => {
        dispatch(setCurrentSection(response._id))
        dispatch(redirect(`/admin/section/${response._id}`))
      })
  }
}

// Async actions for editing section
export const editSection = (id, section) => {
  return async (dispatch) => {
    // Set fetching section to true
    dispatch(editSectionBegin())
    feathersClient.service('sections').patch(id, section)
      .then(response => {
        dispatch(editSectionSuccess(response))
        dispatch(showNotification('Edited section successfully'))
        dispatch(redirect(`/admin/section/${response._id}`))
      })
      .catch(error => {
        dispatch(editSectionFailure(error))
      })
  }
}

// Async action for duplicating section
export const duplicateSection = id => {
  return async (dispatch, getState) => {
    // get existing section from cache or server
    dispatch(fetchSingleSection(id))
      .then(() => {
        const state = getState()
        const sectionToCopy = _.get(state, ['section', 'sectionCache', id], {})
        // create new object
        let newSectionObject = {
          title: `${sectionToCopy.title} - COPY`,
          text: sectionToCopy.text,
          fillins: _.cloneDeep(sectionToCopy.fillins)
        }
        // dispatch newSection with same info
        dispatch(newSection(newSectionObject))
      })
      .catch(error => {
        dispatch(showNotification(`Could not duplicate section. ${error}`))
      })
  }
}
