import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FILLIN } from '../helpers/Constants'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary, Grid, IconButton,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextFormatIcon from '@material-ui/icons/TextFormat'
import ListIcon from '@material-ui/icons/List'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { FillinAccordionText } from './FillinAccordionText'
import FillinAccordionDropdown from './FillinAccordionDropdown'

class FillinAccordion extends Component {
  static propTypes = {
    fillin: PropTypes.shape({
      key: PropTypes.string,
      type: PropTypes.number,
      properties: PropTypes.object
    }),
    handleUpdateProperty: PropTypes.func.isRequired,
    handleDeleteFillin: PropTypes.func.isRequired
  }

  static defaultProps = {
    fillin: {
      key: '',
      type: 0,
      properties: {}
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      dirtyProperties: {}
    }
  }

  fillinWrapper = (WrappedComponent, fillin) => (
    <WrappedComponent
      type={fillin.type}
      fillinKey={fillin.key}
      properties={fillin.properties}
      handleUpdateProperty={this.props.handleUpdateProperty}
    />
  )

  fillinWidget = (fillin) => {
    switch (fillin.type) {
      case FILLIN.TEXT:
        return this.fillinWrapper(FillinAccordionText, fillin)
      case FILLIN.DROPDOWN:
        return this.fillinWrapper(FillinAccordionDropdown, fillin)
      default:
        return <p>Fillin type not found!</p>
    }
  }

  fillinIcon = type => {
    switch (type) {
      case FILLIN.TEXT:
        return <TextFormatIcon />
      case FILLIN.DROPDOWN:
        return <ListIcon />
      default:
        return <ErrorOutlineIcon />
    }
  }

  render () {
    const {classes} = this.props
    return (
      <Accordion expanded={this.props.expanded} onChange={this.props.handleChange(this.props.fillin.key)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {this.fillinIcon(this.props.fillin.type)}
          <Typography>{(this.props.fillin.properties && this.props.fillin.properties.label) || '<New Fillin>'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <strong>Key: </strong>{this.props.fillin.key}{' '}
              <CopyToClipboard
                text={this.props.fillin.key}
              >
                <IconButton
                  size='small'
                  aria-label='Copy'
                  classes={{root: classes.iconButton}}
                ><CopyIcon classes={{root: classes.iconSmall}} /></IconButton>
              </CopyToClipboard>
            </Grid>
            <Grid item xs={12}>
              {this.fillinWidget(this.props.fillin)}
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Grid container>
            <Grid item xs={3} className={classes.iconActions}>
              <IconButton
                size='small'
                aria-label='Delete'
                classes={{root: classes.iconButton}}
                onClick={this.props.handleDeleteFillin}
              ><DeleteIcon /></IconButton>
            </Grid>
          </Grid>
        </AccordionActions>
      </Accordion>
    )
  }
}

const styles = theme => ({
  iconActions: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  iconButton: {
    width: 20,
    height: 20
  },
  iconSmall: {
    width: 16,
    height: 16
  },
  otherActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end'
  }
})

export default withStyles(styles)(FillinAccordion)
