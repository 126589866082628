import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as _ from 'lodash'
import { Link } from 'react-router-dom'
import SectionEditor from '../components/SectionEditor'
import { connect } from 'react-redux'
import { fetchAndSetSection, newSectionAndRedirect, setCurrentSection, editSection } from '../actions/section'
import { compose } from 'redux'
import { FILLIN } from '../helpers/Constants'
import ErrorBox from '../components/ErrorBox'

const styles = {
  dense: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

class SectionPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentSectionObject: null,
      errorMessages: null
    }

    if (props.match.params.id) {
      props.fetchAndSetSection(props.match.params.id)
    }
  }

  componentDidUpdate (prevProps) {
    // Wait until section and cache are loaded, and only load into state once
    if (typeof this.props.sectionCache === 'object' &&
      this.props.sectionCache[this.props.currentSection] &&
      this.state.currentSectionObject === null
    ) {
      this.setState({currentSectionObject: this.props.sectionCache[this.props.currentSection]})
    }

    // Or, if the page had loaded a new section
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({currentSectionObject: this.props.sectionCache[this.props.match.params.id]})
    }
  }

  componentWillUnmount () {
    this.props.clearCurrentSection()
  }

  showFetchingError = () => (
    !this.props.isFetchingSingleSection && this.props.fetchSingleSectionStatus === false
  )

  showNewSection = () => (
    !this.props.isFetchingSingleSection && !this.props.currentSection
  )

  showSection = () => (
    !this.props.isFetchingSingleSection && this.props.currentSection
  )

  currentSection = () => this.props.sectionCache[this.props.currentSection]

  checkForMissingFillins = (tags, fillins) => {
    let missingFillins = []
    tags.forEach(tag => {
      if (typeof fillins[tag] === 'undefined') {
        missingFillins.push(`Missing fillin for tag ${tag}`)
      }
    })
    return missingFillins.length > 0 ? missingFillins : false
  }

  checkForMissingTags = (tags, fillins) => {
    let missingTags = []
    Object.getOwnPropertyNames(fillins).forEach(fillinKey => {
      if (!tags.find(tag => tag === fillinKey)) {
        missingTags.push(`Missing tag for fillin ${fillinKey}`)
      }
    })
    return missingTags.length > 0 ? missingTags : false
  }

  checkForMissingLabel = fillins => {
    let missingLabel = []
    Object.getOwnPropertyNames(fillins).forEach(fillinKey => {
      const fillinObject = fillins[fillinKey]
      if (_.get(fillinObject, ['properties', 'label', 'length'], 0) === 0) {
        missingLabel.push(`Label missing for fillin ${fillinKey}`)
      }
    })
    return missingLabel.length > 0 ? missingLabel : false
  }

  checkForMissingOptions = fillins => {
    let missingLabel = []
    Object.getOwnPropertyNames(fillins).forEach(fillinKey => {
      const fillinObject = fillins[fillinKey]
      if (fillinObject.type === FILLIN.DROPDOWN &&
        _.get(fillinObject, ['properties', 'options', 'length'], 0) < 2
      ) {
        missingLabel.push(`Sufficient options missing for fillin ${fillinKey}`)
      }
    })
    return missingLabel.length > 0 ? missingLabel : false
  }

  handleCommitSection = section => {
    const { text, fillins, title } = section
    let result, match
    let errors = []
    let matches = []
    const regex = /{([A-Za-z0-9]+)}/g
    while (match = regex.exec(text)) { // eslint-disable-line no-cond-assign
      matches.push(match)
    }
    const tags = matches.map(match => match[1])

    // error if no title
    if (title.length <= 0) errors.push('Missing title!')

    // error if tags are missing fillins
    result = this.checkForMissingFillins(tags, fillins)
    if (result) errors = [...errors, ...result]

    // error if array > text (maybe warn?)
    result = this.checkForMissingTags(tags, fillins)
    if (result) errors = [...errors, ...result]

    // error if any labels are blank
    result = this.checkForMissingLabel(fillins)
    if (result) errors = [...errors, ...result]

    // error if any dropdowns have no [options]
    result = this.checkForMissingOptions(fillins)
    if (result) errors = [...errors, ...result]

    if (errors.length === 0) {
      // save order of fillins before saving
      tags.forEach((tag, index) => {
        fillins[tag].order = index
      })

      // update section
      this.props.editSection(this.props.currentSection, section)
      this.setState({errorMessages: null})
    } else {
      // Throw error with error messages
      this.setState({errorMessages: errors})
    }
  }

  handleCloseErrorBox = () => {
    this.setState({errorMessages: null})
  }

  render = () =>
    <div>
      <Link to='/admin/sections'>&#9666; All Sections</Link>
      <h2>SECTION</h2>
      {this.showFetchingError() && <p>Error! Could not load section.</p>}
      {this.state.errorMessages && <ErrorBox errorMessages={this.state.errorMessages} handleClose={this.handleCloseErrorBox} />}
      {this.showNewSection() &&
      <SectionEditor
        commitSection={this.props.newSection}
      />}
      {this.showSection() &&
      <SectionEditor
        section={this.state.currentSectionObject}
        commitSection={section => this.handleCommitSection(section)}
      />}
    </div>
}

const mapStateToProps = state => {
  const {
    isFetchingSingleSection,
    fetchSingleSectionStatus,
    currentSection,
    sectionCache} = state.section
  return {
    isFetchingSingleSection,
    fetchSingleSectionStatus,
    currentSection,
    sectionCache
  }
}

const mapDispatchToProps = dispatch => ({
  newSection: section => dispatch(newSectionAndRedirect(section)),
  editSection: (id, section) => dispatch(editSection(id, section)),
  fetchAndSetSection: id => dispatch(fetchAndSetSection(id)),
  clearCurrentSection: () => dispatch(setCurrentSection(null))
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SectionPage)
