import WhyDoINeedALoveLetter from '../articles/WhyDoINeedALoveLetter'
import WhatShouldIIncludeInMyLoveLetter from '../articles/WhatShouldIIncludeInMyLoveLetter'
import WhatIsALoveLetter from '../articles/WhatIsALoveLetter'
import DoLoveLettersWork from '../articles/DoLoveLettersWork'

export const ARTICLES = {
  'why-do-i-need-a-love-letter': {
    slug: 'why-do-i-need-a-love-letter',
    title: 'Why Do I Need a Love Letter?',
    description: 'An offer letter from a buyer is cheekily known as love letter - it’s essentially a cover letter from a buyer to a seller, included in the offer package assembled by your real estate agent.',
    date: 1536782308908,
    component: WhyDoINeedALoveLetter
  },
  'what-should-i-include-in-my-love-letter': {
    slug: 'what-should-i-include-in-my-love-letter',
    title: 'What Should I Include in My Love Letter?',
    description: 'Maybe you’ve heard stories real estate love letters being successful, and maybe you have a friend who used this tool to stand out over other competitors when buying their home;',
    date: 1536784702732,
    component: WhatShouldIIncludeInMyLoveLetter},
  'what-is-a-love-letter': {
    slug: 'what-is-a-love-letter',
    title: 'What is a Love Letter?',
    description: 'The phrase “love letter” evokes images of an emotional plea, a baring of the soul. For home buyers in competitive markets, love letter also refers to an offer letter written by an interested buyer, to be included in an offer presented to a seller.',
    date: 1536789973527,
    component: WhatIsALoveLetter},
  'do-love-letters-work': {
    slug: 'do-love-letters-work',
    title: 'Do Love Letters Work?',
    description: 'In recent years in hot markets, “love letters” have become very popular among home buyers. The reason behind their popularity lies in the difficulties that many buyers face due to low inventory of homes for sale, especially for first-time buyers.',
    date: 1536790410285,
    component: DoLoveLettersWork}
}
