import {
  SIGN_IN_BEGIN, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
  SIGN_OUT_BEGIN, SIGN_OUT_SUCCESS, SIGN_OUT_FAILURE
} from '../actions/actionTypes'
import { Roles } from '../components/SecureRoute'

const initialState = {
  // User Info
  userId: null,
  email: null,
  roles: [],
  isSignedIn: false,
  // Sign In
  isSigningIn: false,
  signinAttemptStatus: null,
  signinAttemptMessage: '',
  // Sign Out
  isSigningOut: false,
  signoutAttemptStatus: null,
  signoutAttemptMessage: ''
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_BEGIN:
      return Object.assign({}, state, {
        isSigningIn: true,
        signinAttemptStatus: null,
        signinAttemptMessage: ''
      })

    case SIGN_IN_SUCCESS:
      return Object.assign({}, state, {
        isSigningIn: false,
        isSignedIn: true,
        signinAttemptStatus: true,
        signinAttemptMessage: action.signinAttemptMessage,
        email: action.payload.email,
        roles: action.payload.roles || [Roles.USER],
        userId: action.payload.userId
      })

    case SIGN_IN_FAILURE:
      return Object.assign({}, state, {
        isSigningIn: false,
        signinAttemptStatus: false,
        signinAttemptMessage: action.signinAttemptMessage
      })

    case SIGN_OUT_BEGIN:
      return Object.assign({}, state, {
        isSigningOut: true,
        signoutAttemptMessage: ''
      })

    case SIGN_OUT_SUCCESS:
      // Clear out the user info
      return Object.assign({}, state, {
        isSigningOut: false,
        isSignedIn: false,
        email: '',
        roles: [],
        userId: null,
        signoutAttemptStatus: true,
        signoutAttemptMessage: action.signoutAttemptMessage
      })

    case SIGN_OUT_FAILURE:
      return Object.assign({}, state, {
        isSigningOut: false,
        signoutAttemptStatus: false,
        signoutAttemptMessage: action.signoutAttemptMessage
      })

    default:
      return state
  }
}

export default user
