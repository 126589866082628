import React, { Component } from 'react'

class WhyDoINeedALoveLetter extends Component {
  render () {
    return (
      <div>
        <p>An offer letter from a buyer is cheekily known as love letter - it’s essentially a cover letter from a buyer to a seller, included in the offer package assembled by your real estate agent. In times when inventories are short in hot housing markets, a love letter from a buyer can help your offer stand out from the rest. Competitive markets have eager buyers who are ready with cash, which is hard to beat with a traditional financed offer; a love letter gives you a chance to pitch your offer directly to the owner, introducing yourself and explaining your terms and motivations, and helping you compete without cash. Your real estate love letter can make all the difference in promoting your offer to win your bid and buy your dream home.</p>
        <h3>Make your offer stand out with a love letter</h3>
        <p>In hot markets currently, inventories are short and there is great demand among buyers. It has become common for sellers to receive multiple offers from interested buyers, and bidding wars are common, driving the average final price ending up well over asking. When sellers review offers, they’re comparing various timelines, prices, contingencies, and details, and it’s easy for them to blur together no matter how closely they read: a letter directly to them, providing a narrative overview of your offer, stands out and draws them for a closer comparison. With multiple offers on the table, a letter to the sellers that is submitted with your offer, explaining why the property holds great appeal for you and demonstrating your commitment, can be the difference between having your offer overlooked or accepted.</p>
        <h3>Make a lasting impression and connect with the seller</h3>
        <p>It sometimes seems that all sellers want is the highest possible price for their homes, and rightfully so - but there are many sellers whose motivations are more nuanced and who might be swayed to accept slightly less. If they understand from your offer letter that yours is an offer package that benefits them in other ways - in terms, in timelines, and in you as a business partner - an offer that is otherwise unremarkable may suddenly hold more appeal. There are also sellers who have sentimental attachment to the their home and memories made there, and preferring to sell it to a buyer they feel will take great care of the property. Your real estate love letter helps by demonstrating your appreciation for the structure and the spirit of the home, as well as your intent to maintain it in future. By writing a an offer letter, you as a potential buyer help to relieve anxiety in the sellers’ minds, assuring them they’ve made the right choice in accepting your offer over others they received.</p>
        <h3>Total purchase price is important, but your love letter can make it secondary</h3>
        <p>Many aspiring homeowners, particularly first time buyers, are not flush with funds to escalate in bidding wars, but there are plenty of investors and cash buyers ready to throw money at the problem of a competitive market. The only way to compete without more money, is to be more creative - terms and timelines matter, too, but you also need to let the owner know how much you love the home and how that translates into a successful sale for the seller. Cash deals appeal to sellers because they are quick and low risk, but the offer letter is your chance to explain why your offer is the best you can do, how you’ve worked with your lender to be sure your loan will be approved, and what you can do to make the process as smooth as possible for the seller. It’s possible to win a bidding war ever when you’re at the top of your budget.</p>
      </div>
    )
  }
}

export default WhyDoINeedALoveLetter
