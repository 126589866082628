import {REDIRECT, CLEAR_REDIRECT, SHOW_NOTIFICATION, CLEAR_NOTIFICATION, SET_USER_LOCATION} from './actionTypes'

export const redirect = redirect => ({
  type: REDIRECT,
  redirect
})

export const clearRedirect = () => ({
  type: CLEAR_REDIRECT
})

export const showNotification = notification => {
  return {
  type: SHOW_NOTIFICATION,
  notification
}}

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION
})

export const setUserLocation = location => ({
  type: SET_USER_LOCATION,
  location
})
