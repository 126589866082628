import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import HeartIcon from '@material-ui/icons/Favorite'
import MenuDrawer from './MenuDrawer'
import LoginWidgetContainer from '../containers/LoginWidgetContainer'
import { withWidth, Hidden, Button } from '@material-ui/core'
import { Edit, FormatAlignLeft, Home } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { CONSTANTS, ENVIRONMENTS } from '../helpers/Constants'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { isWidthUp } from '@material-ui/core/withWidth'

class Header extends Component {
  static propTypes = {
    templates: PropTypes.array,
    printMode: PropTypes.bool,
    handleTemplateChange: PropTypes.func,
    handlePrintModeChange: PropTypes.func,
    isTemplateSet: PropTypes.bool,
    printHandler: PropTypes.func,
    copyHandler: PropTypes.func,
    saveHandler: PropTypes.func,
    editTemplateHandler: PropTypes.func,
    changeTemplate: PropTypes.func,
    isSignedIn: PropTypes.bool,
    userName: PropTypes.string
  }

  render () {
    const { classes } = this.props
    const isLarge = isWidthUp('md', this.props.width)
    const isHuge = isWidthUp('lg', this.props.width)
    return <div>
      <AppBar className={classes.header} position='static'>
        {CONSTANTS.ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT && <p className={classes.devHeader}>DEV</p>}
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2}>
            <Grid item xs={7} className={classes.topRow}>
              <Link to='/'>
                <h1 className={`${classes.title} ${isLarge ? classes.largeTitle : ''} ${isHuge ? classes.hugeTitle : ''}`}>
                  <HeartIcon color='secondary' className={classes.heart} />&nbsp;Home&nbsp;Love&nbsp;Letter<span className={classes.titleTM}>&trade;</span>
                </h1>
              </Link>
              {isWidthUp('md', this.props.width) && <span className={classes.tagline}>
                Free templates for writing offer letters to home sellers
              </span>}
            </Grid>
            <Hidden mdUp>
              <Grid item xs={5} className={classes.menu}>
                <MenuDrawer
                  printHandler={this.props.printHandler}
                  copyHandler={this.props.copyHandler}
                  saveHandler={this.props.saveHandler}
                  editTemplateHandler={this.props.editTemplateHandler}
                  changeTemplate={this.props.changeTemplate}
                />
                {isWidthUp('md', this.props.width) && <LoginWidgetContainer />}
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={5} className={classes.menuHorizontal}>
                <Button className={classes.headerButtons} component={Link} to='/search'>Write</Button>
                <Button className={classes.headerButtons} component={Link} to='/articles'>Articles</Button>
                <Button className={classes.headerButtons} component={Link} to='/househunting'>House Hunting</Button>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  }
}

const styles = () => ({
  tagline: {
    margin: '0 0 0 32px',
    fontVariant: 'all-small-caps'
  },
  header: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row'
  },
  topRow: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  menu: {
    textAlign: 'right',
    color: 'white'
  },
  devHeader: {
    margin: 0,
    padding: 0,
    fontSize: '0.75em',
    width: '100%',
    backgroundColor: '#e57373',
    position: 'absolute',
    top: 0,
    left: 0
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: '"Roboto Condensed", serif',
    fontSize: '5vw',
    margin: 0,
    display: 'inline',
    letterSpacing: '0.03em',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase'
  },
  largeTitle: {
    fontSize: '4vw'
  },
  hugeTitle: {
    fontSize: '50px'
  },
  heart: {
    fontSize: '1em',
    marginBottom: '-0.15em'
  },
  titleTM: {
    fontFamily: 'sans-serif',
    fontSize: '30%',
    lineHeight: '220%',
    verticalAlign: 'text-top'
  },
  toolbar: {
    maxWidth: 960,
    width: '100%',
    margin: '0 auto'
  },
  headerButtons: {
    color: 'white'
  },
  menuHorizontal: {
    marginTop: '1.4em'
  },
  activeLink: {
    borderBottom: '1px solid white !important'
  }
})

export default compose(
  withStyles(styles),
  withWidth()
)(Header)
