import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Close, ErrorOutline } from '@material-ui/icons'

class ErrorBox extends Component {
  static propTypes = {
    errorMessages: PropTypes.array,
    handleClose: PropTypes.func
  }

  static defaultProps = {
    errorMessage: [],
    handleClose: () => {}
  }

  render () {
    const { classes, handleClose } = this.props
    return (
      <Paper className={classes.root}>
        <IconButton className={classes.closeButton} onClick={handleClose} aria-label='Close'>
          <Close />
        </IconButton>
        {this.props.errorMessages.map(message => <p key={message}>
          <ErrorOutline className={classes.errorIcon} />{message}
        </p>)}
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 30,
    height: 30
  },
  errorIcon: {
    width: 14,
    height: 14,
    marginRight: 10,
    marginBottom: -1,
    color: 'red'
  }
})

export default withStyles(styles)(ErrorBox)
