// Global Actions
export const REDIRECT = 'REDIRECT'
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const SET_USER_LOCATION = 'SET_USER_LOCATION'

// User Actions
export const SIGN_IN_BEGIN = 'SIGN_IN_BEGIN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'

export const SIGN_OUT_BEGIN = 'SIGN_OUT_BEGIN'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE'

// Template Actions
export const FETCH_TEMPLATES_BEGIN = 'FETCH_TEMPLATES_BEGIN'
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS'
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE'
export const FETCH_TEMPLATES_ABORT = 'FETCH_TEMPLATES_ABORT'
export const FETCH_SINGLE_TEMPLATE_BEGIN = 'FETCH_SINGLE_TEMPLATE_BEGIN'
export const FETCH_SINGLE_TEMPLATE_SUCCESS = 'FETCH_SINGLE_TEMPLATE_SUCCESS'
export const FETCH_SINGLE_TEMPLATE_FAILURE = 'FETCH_SINGLE_TEMPLATE_FAILURE'
export const FETCH_SINGLE_TEMPLATE_ABORT = 'FETCH_SINGLE_TEMPLATE_ABORT'
export const INVALIDATE_TEMPLATE_CACHE = 'INVALIDATE_TEMPLATE_CACHE'
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE'
export const DELETE_TEMPLATE_BEGIN = 'DELETE_TEMPLATE_BEGIN'
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS'
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE'
export const NEW_TEMPLATE_BEGIN = 'NEW_TEMPLATE_BEGIN'
export const NEW_TEMPLATE_SUCCESS = 'NEW_TEMPLATE_SUCCESS'
export const NEW_TEMPLATE_FAILURE = 'NEW_TEMPLATE_FAILURE'
export const EDIT_TEMPLATE_BEGIN = 'EDIT_TEMPLATE_BEGIN'
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS'
export const EDIT_TEMPLATE_FAILURE = 'EDIT_TEMPLATE_FAILURE'

// Section Actions
export const FETCH_SECTIONS_BEGIN = 'FETCH_SECTIONS_BEGIN'
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS'
export const FETCH_SECTIONS_FAILURE = 'FETCH_SECTIONS_FAILURE'
export const FETCH_SECTIONS_ABORT = 'FETCH_SECTIONS_ABORT'
export const FETCH_SINGLE_SECTION_BEGIN = 'FETCH_SINGLE_SECTION_BEGIN'
export const FETCH_SINGLE_SECTION_SUCCESS = 'FETCH_SINGLE_SECTION_SUCCESS'
export const FETCH_SINGLE_SECTION_FAILURE = 'FETCH_SINGLE_SECTION_FAILURE'
export const FETCH_SINGLE_SECTION_ABORT = 'FETCH_SINGLE_SECTION_ABORT'
export const INVALIDATE_SECTION_CACHE = 'INVALIDATE_SECTION_CACHE'
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION'
export const DELETE_SECTION_BEGIN = 'DELETE_SECTION_BEGIN'
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS'
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE'
export const NEW_SECTION_BEGIN = 'NEW_SECTION_BEGIN'
export const NEW_SECTION_SUCCESS = 'NEW_SECTION_SUCCESS'
export const NEW_SECTION_FAILURE = 'NEW_SECTION_FAILURE'
export const EDIT_SECTION_BEGIN = 'EDIT_SECTION_BEGIN'
export const EDIT_SECTION_SUCCESS = 'EDIT_SECTION_SUCCESS'
export const EDIT_SECTION_FAILURE = 'EDIT_SECTION_FAILURE'

// TODO: Letter Actions