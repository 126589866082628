import React, { Component } from 'react'
import { IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Link } from 'react-router-dom'
import { Link as LinkIcon } from '@material-ui/icons'

class TemplateEditorAllSections extends Component {
  createOrUpdateDate = (create, update) => {
    const date = new Date(update || create)
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    const toolTip = update ? 'Updated' : 'Created'

    return <span title={toolTip}>{formattedDate}</span>
  }

  sectionPreview = text => {
    return text.replace(/{([a-f0-9]+)}/g, '…').substring(0, 60)
  }

  // TODO: Add filtering here

  render () {
    const { classes, allSections, addSectionToTemplate } = this.props
    return (
      <Paper className={classes.root}>
        <Typography variant='h6'>All Sections</Typography>
        <List>
          {Object.getOwnPropertyNames(allSections).map(sectionKey => {
            const section = allSections[sectionKey]
            const { _id, title, text, createdAt, lastUpdatedAt } = section
            return <ListItem key={sectionKey} className={classes.allSectionsList}>
              <IconButton
                className={classes.iconButton}
                color='secondary'
                aria-label='Add to Template'
                onClick={() => addSectionToTemplate(sectionKey)}
              >
                <AddCircleIcon />
              </IconButton>
              <ListItemText
                className={classes.sectionPreview}
                primary={<span>{title}<Link to={`/admin/section/${_id}`}><LinkIcon className={classes.linkIcon} /></Link></span>}
                secondary={
                  <span>{this.createOrUpdateDate(createdAt, lastUpdatedAt)} - {this.sectionPreview(text || '')}</span>} />
            </ListItem>
          })}
        </List>
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  iconButton: {
    width: 20,
    height: 20,
    color: theme.palette.primary
  },
  linkIcon: {
    width: 15,
    height: 15,
    color: 'black',
    marginLeft: theme.spacing(1),
    marginBottom: -2
  },
  allSectionsList: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

export default withStyles(styles)(TemplateEditorAllSections)
