import { connect } from 'react-redux'
import Loading from '../components/Loading'

const mapStateToProps = state => {
  // Connect anything which requires a global spinner here
  const { isSigningIn, isSigningOut } = state.user
  const { isFetchingTemplates, isFetchingSingleTemplate, isDeletingTemplate } = state.template

  return {loading:
    isSigningIn ||
    isSigningOut ||
    isFetchingTemplates ||
    isFetchingSingleTemplate ||
    isDeletingTemplate
  }
}

export default connect(mapStateToProps)(Loading)
