import React, { Component } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class ComingSoonPage extends Component {

  render () {
    const { classes } = this.props
    return (<div>
      <Paper elevation={4} className={classes.root}>
        <Typography variant='h4' gutterBottom>
          Home Love Letter
        </Typography>
        <Typography variant='h5'>More exciting features and templates are coming soon.</Typography>
        <div id='mc_embed_signup' style={{background: '#fff', clear: 'left', font: '14px Helvetica,Arial,sans-serif', maxWidth: '400px'}} >
          <form action='https://unionjackcreative.us4.list-manage.com/subscribe/post?u=3db2fdf980650b91e63237874&amp;id=33ea0216a5' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' className='validate' target='_blank' noValidate>
            <div id='mc_embed_signup_scroll'>
              <h2>Sign up for real estate tips and Home Love Letter&trade; Updates</h2>
              <div className='indicates-required'><span className='asterisk'>*</span> indicates required</div>
              <div className='mc-field-group'>
                <label htmlFor='mce-EMAIL'>Email Address <span className='asterisk'>*</span></label>
                <input type='email' name='EMAIL' className='required email' id='mce-EMAIL' />
              </div>
              <div className='mc-field-group'>
                <label htmlFor='mce-MMERGE5'>Zip Code </label>
                <input type='text' name='MMERGE5' className='' id='mce-MMERGE5' />
              </div>
              <div id='mce-responses' className='clear'>
                <div className='response' id='mce-error-response' style={{display: 'none'}} />
                <div className='response' id='mce-success-response' style={{display: 'none'}} />
              </div>
              <div style={{position: 'absolute', left: '-5000px', ariaHidden: 'true'}}>
                <input type='text' name='b_3db2fdf980650b91e63237874_33ea0216a5' tabIndex='-1' value='' />
              </div>
              <div className='clear'>
                <input type='submit' value='Subscribe' name='subscribe' id='mc-embedded-subscribe' className='button' />
              </div>
            </div>
          </form>
        </div>
      </Paper>
    </div>)
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  chip: {
    margin: 4
  },
  header: {
    paddingBottom: theme.spacing(2)
  },
  sectionHeaders: {
    paddingTop: theme.spacing(1)
  }
})

export default withStyles(styles)(ComingSoonPage)
