import {
  FETCH_SECTIONS_BEGIN,
  FETCH_SECTIONS_SUCCESS,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_ABORT,
  FETCH_SINGLE_SECTION_BEGIN,
  FETCH_SINGLE_SECTION_SUCCESS,
  FETCH_SINGLE_SECTION_FAILURE,
  FETCH_SINGLE_SECTION_ABORT,
  INVALIDATE_SECTION_CACHE,
  SET_CURRENT_SECTION,
  DELETE_SECTION_BEGIN, DELETE_SECTION_SUCCESS, DELETE_SECTION_FAILURE,
  NEW_SECTION_BEGIN, NEW_SECTION_SUCCESS, NEW_SECTION_FAILURE,
  EDIT_SECTION_BEGIN, EDIT_SECTION_SUCCESS, EDIT_SECTION_FAILURE
} from '../actions/actionTypes'

const initialState = {
  isFetchingSections: false,
  fetchSectionsStatus: null,
  fetchSectionsMessage: '',
  isFetchingSingleSection: false,
  fetchSingleSectionStatus: null,
  fetchSingleSectionMessage: '',
  sectionCache: {},
  sectionCacheTimestamp: null,
  currentSection: null,
  isDeletingSection: false,
  deleteSectionStatus: null,
  deleteSectionMessage: '',
  isAddingSection: false,
  newSectionStatus: null,
  newSectionMessage: ''
}

const section = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SECTIONS_BEGIN:
      return Object.assign({}, state, {
        isFetchingSections: true,
        fetchSectionsStatus: null,
        fetchSectionsMessage: ''
      })
    case FETCH_SECTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingSections: false,
        fetchSectionsStatus: true,
        fetchSectionsMessage: `Successfully fetched ${action.sections.length} sections.`,
        sectionCache: action.sections,
        sectionCacheTimestamp: Date.now()
      })
    case FETCH_SECTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetchingSections: false,
        fetchSectionsStatus: false,
        fetchSectionsMessage: `Failed to fetch sections: ${action.errorMessage}`
      })
    case FETCH_SECTIONS_ABORT:
      return Object.assign({}, state, {
        isFetchingSections: false,
        fetchSectionsStatus: true,
        fetchSectionsMessage: 'Sections up-to-date.'
      })
    case FETCH_SINGLE_SECTION_BEGIN:
      return Object.assign({}, state, {
        isFetchingSingleSection: true,
        fetchSingleSectionStatus: null,
        fetchSingleSectionMessage: ''
      })
    case FETCH_SINGLE_SECTION_SUCCESS:
      return Object.assign({}, state, {
        isFetchingSingleSection: false,
        fetchSingleSectionStatus: true,
        fetchSingleSectionMessage: `Successfully fetched section ${action.section.title}.`,
        sectionCache: Object.assign({}, state.sectionCache, {[action.section._id]: action.section})
      })
    case FETCH_SINGLE_SECTION_FAILURE:
      return Object.assign({}, state, {
        isFetchingSingleSection: false,
        fetchSingleSectionStatus: false,
        fetchSingleSectionMessage: 'Failed to fetch single section!'
      })
    case FETCH_SINGLE_SECTION_ABORT:
      return Object.assign({}, state, {
        isFetchingSingleSection: false,
        fetchSingleSectionStatus: true,
        fetchSingleSectionMessage: 'Section up-to-date.',
      })
    case INVALIDATE_SECTION_CACHE:
      return Object.assign({}, state, {
        sectionCacheTimestamp: null
      })
    case SET_CURRENT_SECTION:
      return Object.assign({}, state, {
        currentSection: action.id
      })
    case DELETE_SECTION_BEGIN:
      return Object.assign({}, state, {
        isDeletingSection: true
      })
    case DELETE_SECTION_SUCCESS:
      const { [action.id]: value, ...newCache } = state.sectionCache
      return Object.assign({}, state, {
        isDeletingSection: false,
        deleteSectionStatus: true,
        deleteSectionMessage: 'Successfully deleted section',
        sectionCache: newCache
      })
    case DELETE_SECTION_FAILURE:
      return Object.assign({}, state, {
        isDeletingSection: false,
        deleteSectionStatus: false,
        deleteSectionMessage: `Failed to delete section: ${action.error}`
      })
    case NEW_SECTION_BEGIN:
      return Object.assign({}, state, {
        isAddingSection: true
      })
    case NEW_SECTION_SUCCESS:
      return Object.assign({}, state, {
        isAddingSection: false,
        newSectionStatus: true,
        newSectionMessage: 'Successfully created new section.',
        sectionCache: Object.assign({}, state.sectionCache, {
          [action.section._id]: action.section
        })
      })
    case NEW_SECTION_FAILURE:
      return Object.assign({}, state, {
        isAddingSection: false,
        newSectionStatus: false,
        newSectionMessage: action.error
      })
    case EDIT_SECTION_BEGIN:
      return Object.assign({}, state, {
        isEditingSection: true
      })
    case EDIT_SECTION_SUCCESS:
      return Object.assign({}, state, {
        isEditingSection: false,
        editSectionStatus: true,
        editSectionMessage: 'Successfully edited section.',
        sectionCache: Object.assign({}, state.sectionCache, {
          [action.section._id]: action.section
        })
      })
    case EDIT_SECTION_FAILURE:
      return Object.assign({}, state, {
        isEditingSection: false,
        editSectionStatus: false,
        editSectionMessage: action.error
      })
    default:
      return state
  }
}

export default section
