import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'

import {
  Button,
  Grid, TextField, Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class FillinAccordionDropdown extends Component {
  static propTypes = {
    fillinKey: PropTypes.string.isRequired,
    properties: PropTypes.object,
    handleUpdateProperty: PropTypes.func.isRequired
  }

  static defaultProps = {
    fillinKey: '',
    properties: {}
  }

  constructor (props) {
    super(props)
    const options = _.get(props, ['properties', 'options'], [])
    this.state = {
      optionsString: options.count > 0 ? options.join(';') : ''
    }
  }

  parseOptions = () => {
    this.props.handleUpdateProperty('options', this.state.optionsString.split(';'))
  }

  render () {
    const {
      label = '',
      helperText = '',
      size = 'medium',
      options = [],
      dictionaryEntry = ''
    } = this.props.properties
    const { handleUpdateProperty, classes } = this.props

    return <form>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id='label'
            label='Label'
            value={label}
            onChange={e => handleUpdateProperty('label', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='helperText'
            label='Helper Text'
            value={helperText}
            onChange={e => handleUpdateProperty('helperText', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='size'
            label='Size'
            type='number'
            value={size}
            onChange={e => handleUpdateProperty('size', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Options Settings</Typography>
          <Typography variant='caption'>Note: you must parse options before saving.</Typography>
          <ol>
            {options.map((option, index) => <li key={`${index}${option}`}>{option}</li>)}
          </ol>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='options'
            label='Options'
            helperText='Separate options with a semicolon (;).'
            value={this.state.optionsString}
            onChange={e => this.setState({optionsString: e.target.value})}
            classes={{root: classes.optionInput}}
          /><br />
          <Button variation='flat' color='secondary' onClick={this.parseOptions}>Parse Options</Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='dictionaryEntry'
            label='Dictionary Entry'
            helperText='Automatically fill from user dictionary.'
            value={dictionaryEntry}
            onChange={e => handleUpdateProperty('dictionaryEntry', e.target.value)}
          />
        </Grid>
      </Grid>
    </form>
  }
}

const styles = theme => ({
  optionInput: {
    width: '100%'
  }
})

export default withStyles(styles)(FillinAccordionDropdown)
