import feathersClient from '../helpers/FeathersClient'
import {
  FETCH_TEMPLATES_BEGIN,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_ABORT,
  INVALIDATE_TEMPLATE_CACHE,
  SET_CURRENT_TEMPLATE,
  FETCH_SINGLE_TEMPLATE_BEGIN,
  FETCH_SINGLE_TEMPLATE_SUCCESS,
  FETCH_SINGLE_TEMPLATE_ABORT,
  FETCH_SINGLE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_BEGIN,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  NEW_TEMPLATE_BEGIN,
  NEW_TEMPLATE_SUCCESS,
  NEW_TEMPLATE_FAILURE,
  EDIT_TEMPLATE_BEGIN,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAILURE
} from './actionTypes'
import { redirect, showNotification } from './global'

export const fetchTemplatesBegin = () => ({
  type: FETCH_TEMPLATES_BEGIN
})

export const fetchTemplatesSuccess = templates => ({
  type: FETCH_TEMPLATES_SUCCESS,
  templates
})

export const fetchTemplatesFailure = errorMessage => ({
  type: FETCH_TEMPLATES_FAILURE,
  errorMessage
})

export const fetchTemplatesAbort = () => ({
  type: FETCH_TEMPLATES_ABORT
})

export const fetchSingleTemplateBegin = () => ({
  type: FETCH_SINGLE_TEMPLATE_BEGIN
})

export const fetchSingleTemplateSuccess = template => ({
  type: FETCH_SINGLE_TEMPLATE_SUCCESS,
  template
})

export const fetchSingleTemplateAbort = id => ({
  type: FETCH_SINGLE_TEMPLATE_ABORT,
  id
})

export const fetchSingleTemplateFailure = () => ({
  type: FETCH_SINGLE_TEMPLATE_FAILURE
})

export const invalidateTemplateCache = () => ({
  type: INVALIDATE_TEMPLATE_CACHE
})

export const setCurrentTemplate = id => ({
  type: SET_CURRENT_TEMPLATE,
  id
})

export const deleteTemplateBegin = id => ({
  type: DELETE_TEMPLATE_BEGIN,
  id
})

export const deleteTemplateSuccess = id=> ({
  type: DELETE_TEMPLATE_SUCCESS,
  id
})

export const deleteTemplateFailure = error => ({
  type: DELETE_TEMPLATE_FAILURE,
  error
})

export const newTemplateBegin = () => ({
  type: NEW_TEMPLATE_BEGIN
})

export const newTemplateSuccess = template => ({
  type: NEW_TEMPLATE_SUCCESS,
  template
})

export const newTemplateFailure = () => ({
  type: NEW_TEMPLATE_FAILURE
})

export const editTemplateBegin = () => ({
  type: EDIT_TEMPLATE_BEGIN
})

export const editTemplateSuccess = template => ({
  type: EDIT_TEMPLATE_SUCCESS,
  template
})

export const editTemplateFailure = () => ({
  type: EDIT_TEMPLATE_FAILURE
})

//   ASYNC   //

// Async action for fetching all templates
const shouldUpdateTemplateCache = state => {
  const timestamp = state.template.templateCacheTimestamp
  return !timestamp || timestamp < (Date.now() - (1000 * 60 * 60))
} // 1 Hour TTL

export const fetchAllTemplates = () => {
  return (dispatch, getState) => {
    dispatch(fetchTemplatesBegin())
    if (shouldUpdateTemplateCache(getState())) {
      return feathersClient.service('templates').find({limit: 100})
        .then(response => {
          // parse templates here
          let allTemplates = {}
          response.data.forEach(template => {
            allTemplates[template._id] = template
          })
          dispatch(fetchTemplatesSuccess(allTemplates))
        })
        .catch(error => {
          dispatch(fetchTemplatesFailure(error))
        })
    } else {
      dispatch(fetchTemplatesAbort())
    }
  }
}

// Async action to fetch one template, if needed
export const fetchSingleTemplate = id => {
  return async (dispatch, getState) => {
    // Set fetching template to true
    dispatch(fetchSingleTemplateBegin())
    const state = getState()
    if (state.template.templateCache[id]) {
      // If template exists in cache, set it as current
      dispatch(fetchSingleTemplateAbort(id))
    } else {
      // Otherwise try to get it from the server
      return feathersClient.service('templates').get(id)
        .then(response => {
          dispatch(setCurrentTemplate(id))
          dispatch(fetchSingleTemplateSuccess(response))
        })
        .catch(() => dispatch(fetchSingleTemplateFailure()))
    }
  }
}

export const refreshTemplateCache = () => {
  return dispatch => {
    dispatch(invalidateTemplateCache())
    dispatch(fetchAllTemplates())
  }
}

// Async actions for delete template
export const deleteTemplate = id => {
  return async (dispatch) => {
    // Set fetching template to true
    dispatch(deleteTemplateBegin())
    feathersClient.service('templates').remove(id)
      .then(response => {
        dispatch(showNotification('Template deleted.'))
        dispatch(deleteTemplateSuccess(id))
      })
      .catch(error => {
        dispatch(deleteTemplateFailure(error))
      })
  }
}

// Async actions for creating new template
export const newTemplate = template => {
  return async (dispatch) => {
    // Set fetching template to true
    dispatch(newTemplateBegin())
    feathersClient.service('templates').create(template)
      .then(response => {
        dispatch(newTemplateSuccess(response))
        dispatch(showNotification('Added new template!'))
        dispatch(setCurrentTemplate(response._id))
        dispatch(redirect(`/admin/template/${response._id}`))
      })
      .catch(error => {
        dispatch(newTemplateFailure(error))
      })
  }
}

// Async actions for editing template
export const editTemplate = (id, template) => {
  return async (dispatch) => {
    // Set fetching template to true
    dispatch(editTemplateBegin())
    feathersClient.service('templates').patch(id, template)
      .then(response => {
        dispatch(editTemplateSuccess(response))
        dispatch(showNotification('Edited template successfully'))
        dispatch(redirect(`/admin/template/${response._id}`))
      })
      .catch(error => {
        dispatch(editTemplateFailure(error))
      })
  }
}
