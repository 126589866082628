import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { ARTICLES } from '../helpers/ArticleList'
import { Paper, Typography, Button } from '@material-ui/core'
import WritePencil from '../components/icons/WritePencil'
import { formatDate } from '../helpers/HelperFunctions'

class ArticlePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentArticle: ARTICLES[props.match.params.title] ? props.match.params.title : null
    }
  }

  componentWillReceiveProps (nextProps, nextContent) {
    if (nextProps.match.params.title !== this.state.currentArticle) {
      this.setState({currentArticle: ARTICLES[nextProps.match.params.title] ? nextProps.match.params.title : null})
    }
  }

  articleTitle = () => {
    return this.state.currentArticle ? ARTICLES[this.state.currentArticle].title : 'Article Not Found'
  }

  articleDate = () => {
    if (this.state.currentArticle) {
      return formatDate(ARTICLES[this.state.currentArticle].date)
    } else {
      return ''
    }
  }

  adjacentArticle = position => {
    let currentArticlePosition = null
    let articleArray = Object.getOwnPropertyNames(ARTICLES).map((articleKey, position) => {
      if (articleKey === this.state.currentArticle) currentArticlePosition = position
      return ARTICLES[articleKey]
    })
    articleArray.sort((a, b) => {
      if (a.date < b.date) return -1
      if (a.date > b.date) return 1
      return 0
    })
    const newPosition = (currentArticlePosition + position)
    if (newPosition >= 0 && newPosition < Object.getOwnPropertyNames(ARTICLES).length) {
      const adjacentArticle = articleArray[newPosition]
      return <Link to={`/article/${adjacentArticle.slug}`}>“{adjacentArticle.title}”</Link>
    } else {
      return null
    }
  }

  render () {
    const { classes } = this.props
    const ArticleContent = this.state.currentArticle ? ARTICLES[this.state.currentArticle].component : null
    return <div>
      <Link to='/articles'>&#9666; All Articles</Link>
      <Paper className={classes.root}>
        <Typography variant='h4'>{this.articleTitle()}</Typography>
        <p><em>{this.articleDate()}</em></p>
        {this.state.currentArticle && <ArticleContent />}
        <div className={classes.buttonGrid}>
          <Button variant='contained' color='secondary' className={classes.button} component={Link} to='/search'>
            <WritePencil style={{fontSize: 16, marginRight: 12}} />
            Start your letter now
          </Button>
        </div>
        <div className={classes.articlePager}>
          <div className={classes.previous}>
            <span>&lt; Previous Article</span><br />
            <span>{this.adjacentArticle(-1)}</span>
          </div>
          <div className={classes.next}>
            <span>Next Article &gt; </span><br />
            <span>{this.adjacentArticle(1)}</span>
          </div>
        </div>
      </Paper>
    </div>
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2em'
  },
  articlePager: {
    marginTop: '3em',
    overflow: 'auto'
  },
  next: {
    float: 'right',
    textAlign: 'right'
  },
  previous: {
    float: 'left'
  }
})

export default withStyles(styles)(ArticlePage)
