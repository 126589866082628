import React, { Component } from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

class LetterSectionPreview extends Component {
  static propTypes = {
    text: PropTypes.string,
    fillins: PropTypes.object,
    letterValues: PropTypes.object,
    handleSelectFillin: PropTypes.func,
    selectedFillin: PropTypes.string
  }

  static defaultProps = {
    text: '',
    fillins: {},
    letterValues: {}
  }

  componentDidUpdate = () => {
    // if we are mobile AND under a certain size change this behavior
    // rather than scroll into view, make the entry box full screen
    if (this.selectedSpan) this.selectedSpan.scrollIntoView(false) // TODO: change this
  }

  generatePreview = () => {
    const { fillins, letterValues, classes } = this.props
    let { text } = this.props
    let match
    let segments = []

    let fillinCount = 0
    // iterate over template while match is true
    // eslint-disable-next-line
    while(match = text.match(/{([a-f0-9]+)}/)) {
      // push text section to array
      segments.push(
        <span
          className={classes.textSegment}
          key={`text${fillinCount}`}
        >
          {text.substr(0, match.index)}
        </span>
      )

      // get fillin id
      let fillinKey = match[1]
      let fillinInfo = fillins[fillinKey]
      let fillinValue = letterValues[fillinKey]
      const displayValue = fillinValue || ''
      const isSelected = fillinKey === this.props.selectedFillin

      let fillinClasses

      if (isSelected) {
        fillinClasses = `${classes.previewFillin} ${classes.selectedFillin}`
      } else {
        fillinClasses = `${classes.previewFillin}`
      }

      if (displayValue === '') fillinClasses = `${fillinClasses} ${classes.isEmpty}`

      const fillinSize = parseInt(_.get(fillinInfo, ['properties', 'info'], 0), 10)

      // lookup captured tag in fillins property
      if (fillinInfo && fillinInfo.properties) {
        segments.push(
          <span key={fillinKey} className={classes.fillinConatainer}>
            <span
              className={fillinClasses}
              style={displayValue ? {} : {minWidth: `${Math.max(fillinSize, 6)}em`}}
              onClick={() => this.props.handleSelectFillin(fillinKey)}
              ref={el => { if (isSelected) this.selectedSpan = el }}
            >
              {displayValue}
            </span>
            {(displayValue === '' || isSelected) &&
            <div className={classes.fillinLabel}>{fillinInfo.properties.label}</div>
            }
          </span>
        )
      } else {
        segments.push(<span key={fillinKey} style={{color: 'red'}}>Not Found</span>)
      }

      // chop template after the indicator
      text = text.substr(match.index + match[0].length)
      fillinCount++
    }

    // Push any remainder
    segments.push(<span className={classes.textSegment} key={`remainder`}>{text}</span>)

    return segments
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {this.generatePreview(this.props.text, this.props.fillins)}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  fillinConatainer: {
    position: 'relative',
    lineHeight: '2.2em'
  },
  previewFillin: {
    cursor: 'pointer',
    border: 0,
    borderBottom: '1.5px dotted',
    marginBottom: '-.4em',
    display: 'inline',
    height: '1.3em',
    padding: '0 0.5em'
  },
  fillinLabel: {
    position: 'absolute',
    marginTop: '0',
    left: '0',
    fontSize: '.6em',
    color: 'grey',
    lineHeight: '.4em',
    whiteSpace: 'nowrap'
  },
  selectedFillin: {
    borderBottom: '1.5px solid',
    lineHeight: '1.6em',
    fontWeight: 'bold'
  },
  isEmpty: {
    display: 'inline-block'
  },
  textSegment: {
    lineHeight: '2.2em',
    whiteSpace: 'pre-wrap'
  }
})

export default withStyles(styles)(LetterSectionPreview)
