import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  IconButton,
  Avatar,
  Fab,
  Paper,
  Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import LetterIcon from '@material-ui/icons/FormatAlignLeft'
import AddIcon from '@material-ui/icons/Add'
import { fetchAllSections, deleteSection, duplicateSection } from '../actions/section'
import { redirect } from '../actions/global'
import { FileCopy } from '@material-ui/icons'

const SectionRow = ({sectionData, handleDelete, handleDuplicate}) => {
  const formatDate = date => {
    const dateObject = new Date(date)
    const dateString = dateObject.toLocaleDateString()
    return typeof dateString === 'string' ? dateString : ''
  }
  const {_id, createdAt} = sectionData
  return <ListItem
    button
    component={Link}
    to={`/admin/section/${_id}`}
  >
    <ListItemAvatar>
      <Avatar>
        <LetterIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={sectionData.title}
      secondary={`${formatDate(createdAt)}`}
    />
    <ListItemSecondaryAction>
      <Tooltip title='Duplicate section'>
        <IconButton aria-label='Duplicate' onClick={handleDuplicate}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete section'>
        <IconButton aria-label='Delete' onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  </ListItem>
}

class SectionsPage extends Component {
  constructor (props) {
    super(props)
    props.fetchAllSections()
  }

  handleNewSection = () => {
    this.props.redirect('/admin/section')
  }

  showFetchingError = () => (
    !this.props.isFetchingSections && this.props.fetchSectionsStatus === false
  )

  showSection = () => (
    !this.props.isFetchingSections && this.props.fetchSectionsStatus
  )

  render () {
    const { classes } = this.props
    return (
      <div>
        <Typography variant='h6'>
          Sections
        </Typography>
        {this.showFetchingError() && <p>Error, could not load sections: {this.props.fetchSectionsMessage}</p>}
        {this.showSection() &&
        <Paper className={classes.root}>
          <List dense>
            {Object.getOwnPropertyNames(this.props.sectionCache).map(sectionId => {
              const section = this.props.sectionCache[sectionId]
              const { _id } = section
              return (
                <SectionRow
                  key={_id}
                  sectionData={section}
                  handleDelete={() => this.props.deleteSection(_id)}
                  handleDuplicate={() => this.props.duplicateSection(_id)}
                />)
            })}
          </List>
          <Fab color='primary' aria-label='add' onClick={this.handleNewSection}>
            <AddIcon />
          </Fab>
        </Paper>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    isFetchingSections,
    fetchSectionsStatus,
    fetchSectionsMessage,
    sectionCache
  } = state.section
  return {
    isFetchingSections,
    fetchSectionsStatus,
    fetchSectionsMessage,
    sectionCache
  }
}

const mapDispatchToProps = dispatch => ({
  deleteSection: id => dispatch(deleteSection(id)),
  duplicateSection: id => dispatch(duplicateSection(id)),
  fetchAllSections: () => dispatch(fetchAllSections()),
  redirect: to => dispatch(redirect(to))
})

const styles = theme => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  dense: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SectionsPage)
