import { combineReducers } from 'redux'
import global from './global'
import user from './user'
import template from './template'
import section from './section'

export default combineReducers({
  global,
  user,
  template,
  section
})
