import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'

/**
 * Login Widget for header bar
 *
 * Displays a login link, or current login status and logout link if currently logged in.
 */
export default class Loading extends Component {
  static propTypes = {
    loading: PropTypes.bool
  }

  static defaultProps = {
    loading: false
  }

  render = () =>
    <div>
      {this.props.loading && <CircularProgress className='globalLoading' />
      }
    </div>
}
