import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

class TagDialog extends Component {
  static propTypes = {
    chipClass: PropTypes.string.isRequired,
    handleClose: PropTypes.func,
    tags: PropTypes.array,
    open: PropTypes.bool
  }

  static defaultProps = {
    open: false
  }

  render () {
    const { classes } = this.props
    return (
      <Dialog onClose={() => this.props.handleClose(false)} open={this.props.open}>
        <div className={classes.dialog}>
          {this.props.tags.map(tag => (
            <Chip
              key={tag}
              label={tag}
              className={this.props.chipClass}
              onClick={() => this.props.handleClose(tag)}
            />
          ))}
        </div>
      </Dialog>
    )
  }
}

const styles = () => ({
  dialog: {
    padding: '1em'
  }
})

export default withStyles(styles)(TagDialog)
