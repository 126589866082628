import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Clear } from '@material-ui/icons'

/**
 * Login Widget for header bar
 *
 * Displays a login link, or current login status and logout link if currently logged in.
 */
class LoginWidget extends Component {
  static propTypes = {
    isSignedIn: PropTypes.bool,
    email: PropTypes.string,
    signOut: PropTypes.func
  }

  static defaultProps = {
    isSignedIn: false,
    email: '',
    signOut: () => {}
  }

  logOutButton = classes => (
    <IconButton className={classes.signOut} onClick={this.props.signOut} aria-label='Sign Out'>
      <Clear className={classes.clearButton} />
    </IconButton>
  )

  render = () => {
    const { classes } = this.props
    return <div>
      {this.props.isSignedIn
        ? <p className={classes.loginContainer}>
          <Link className={classes.email} to='/login'>{this.props.email}</Link>
          {this.logOutButton(classes)}
        </p>
        : <p className={classes.loginContainer}><Link className={classes.email} to='/login'>Login</Link></p>
      }
    </div>
  }
}

const styles = {
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 0,
    color: 'white'
  },
  email: {
    color: 'white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    width: 'calc(100% - 40px)'
  },
  signOut: {
    height: 20,
    width: 20
  },
  clearButton: {
    color: 'white',
    height: 15,
    width: 15
  }
}

export default withStyles(styles)(LoginWidget)
