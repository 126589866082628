import React, { Component } from 'react'
import { FormControl, FormHelperText, Input, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

class LetterEditorFillinDropdown extends Component {
  static propTypes = {
    properties: PropTypes.object,
    value: PropTypes.string,
    handleUpdate: PropTypes.func
  }

  static defaultProps = {
    properties: {},
    value: '',
    handleUpdate: () => {}
  }

  constructor (props) {
    super(props)
    this.state = {
      open: true,
      updatingValue: ''
    }
    this.dropdownInput = React.createRef()
  }

  componentDidMount () {
    this.setState({open: true})
  }

  componentDidUpdate (prevProps) {
    if (this.state.open === false &&
      prevProps !== this.props &&
      (this.state.updatingValue !== this.props.value || this.props.value === '')
    ) {
      this.setState({open: true})
    }
  }

  handleChangeValue = value => {
    this.setState({updatingValue: value}, this.props.handleUpdate(value))
    this.handleCloseDropdown()
  }

  handleOpenDropdown = () => {
    this.setState({open: true})
  }

  handleCloseDropdown = () => {
    this.setState({open: false})
  }

  render () {
    const { defaultValue = '', options = [] } = this.props.properties
    const { value, classes } = this.props
    return (
      <FormControl className={classes.select}>
        <Select
          value={value || defaultValue}
          onChange={e => this.handleChangeValue(String(e.target.value))}
          onClose={this.handleCloseDropdown}
          onOpen={this.handleOpenDropdown}
          input={<Input name='dropdown' id='dropdown' />}
          inputRef={input => { this.dropdownInput = input }}
          open={this.state.open}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {options.map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
        </Select>
        <p className={classes.hintText}>Hint: If you don't like any of these options click "Customize Letter" once you have filled out all of the fields to customize the text to your heart's content!</p>
      </FormControl>
    )
  }
}

const styles = theme => ({
  select: {
    width: '100%'
  },
  hintText: {
    fontSize: '.75em',
    color: 'rgba(0, 0, 0, 0.54)'
  }
})

export default withStyles(styles)(LetterEditorFillinDropdown)
