import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Dialog } from '@material-ui/core'
import { Favorite } from '@material-ui/icons'

class FinalizeLetterWidget extends Component {
  static propTypes = {
    text: PropTypes.string,
    backToTemplate: PropTypes.func
  }

  static defaultProps = {}

  constructor (props) {
    super(props)
    this.state = {
      text: this.props.text,
      isDirty: false,
      showExitDialog: false
    }
  }

  handleChangeText = text => {
    this.setState({
      text: text,
      isDirty: true
    })
  }

  backToTemplate = () => {
    if (this.state.isDirty) {
      this.setState({showExitDialog: true})
    } else {
      this.props.backToTemplate()
    }
  }

  handleCloseDialog = () => {
    this.setState({showExitDialog: false})
  }

  render () {
    return (
      <div>
        <a onClick={this.backToTemplate}>◀ Back to template</a>
        <TextField
          label='Finalize Letter Text:'
          multiline
          fullWidth
          value={this.state.text}
          onChange={e => { this.handleChangeText(e.target.value) }}
        />
        <Dialog
          open={this.state.showExitDialog}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle><Favorite />Abandon Customization?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to go back to the template? Customizations made on this page <strong>will not be saved</strong>. Make sure you have saved or printed your letter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.backToTemplate} color='primary'>
              Back to template
            </Button>
            <Button onClick={this.handleCloseDialog} color='primary' autoFocus>
              Stay Here
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FinalizeLetterWidget
