import feathersClient from '../helpers/FeathersClient'
import {
  SIGN_IN_BEGIN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_OUT_BEGIN,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS
} from './actionTypes'
import { redirect } from './global'
import { Roles } from '../components/SecureRoute'
import { showNotification } from './global'

export const signInBegin = () => ({
  type: SIGN_IN_BEGIN
})

export const signInSuccess = payload => dispatch => {
  const signinAttemptMessage = 'Logged in successfully!'
  dispatch(showNotification(signinAttemptMessage))
  dispatch({
    type: SIGN_IN_SUCCESS,
    signinAttemptMessage: signinAttemptMessage,
    payload
  })
}

export const signInFailure = loginFailureMessage => dispatch => {
  dispatch(showNotification(loginFailureMessage))
  dispatch({
    type: SIGN_IN_FAILURE,
    signinAttemptMessage: loginFailureMessage
  })
}

export const signOutBegin = () => ({
  type: SIGN_OUT_BEGIN
})

export const signOutSuccess = () => dispatch => {
  const signoutmessage = 'Signing out.'
  dispatch(showNotification(signoutmessage))
  dispatch({
    type: SIGN_OUT_SUCCESS,
    signoutAttemptMessage: signoutmessage
  })
}

export const signOutFailure = () => dispatch => {
  const signoutmessage = 'Failed to sign out!'
  dispatch(showNotification(signoutmessage))
  dispatch({
    type: SIGN_OUT_FAILURE,
    signoutAttemptMessage: signoutmessage
  })
}

// Async action for validating the user
export const validateUser = (email, password, history) => dispatch => {
  dispatch(signInBegin())
  // verify we have email and password and simple client side validation
  if (!(typeof email === 'string' && email.length > 0 &&
    typeof password === 'string' && password.length > 0)) {
    dispatch(signInFailure('Invalid email or password. Please check your input.'))
  } else {
    // start ajax request

    return feathersClient.authenticate(
      {
        strategy: 'local',
        email,
        password
      }
    )
      .then(() => {
        // Successful Login
        // get the JWT token, unpack it and pass the details into the loggedIn function
        // feathersClient.passport.getJWT().then(token => {
        //   if (feathersClient.passport.payloadIsValid(token)) {
        //     feathersClient.passport.verifyJWT(token)
        //       .then(payload => {
        //         // Take name and permission level from payload and save to state.
        //         dispatch(signInSuccess(payload))
        //         dispatch(redirect(
        //           payload['roles'].includes(Roles.ADMIN) ? '/admin' : '/myletters'
        //         ))
        //       })
        //   } else {
        //     dispatch(signInFailure('Could not read token!'))
        //   }
        // })
        feathersClient.get('authentication')
          .then(data => {
            const {roles} = data.user
            dispatch(signInSuccess(data.user))
            const redirectPath = roles.includes(Roles.ADMIN) ? '/admin' : '/myletters'
            setTimeout(() => history.push(redirectPath), 2000)
            //dispatch(redirect(roles.includes(Roles.ADMIN) ? '/admin' : '/myletters'))
          })
          .catch(error => {
            dispatch(signInFailure('Could not authenticate. This is a local error.: ' + error))
          })
      })
      .catch((error) => {
        dispatch(signInFailure('Login rejected: ' + error))
      })
  }
}

// Async action for signing the user our
export const signOut = () => {
  return dispatch => {
    dispatch(signOutBegin())
    feathersClient.logout()
      .then(() => {
        dispatch(signOutSuccess())
        dispatch(redirect('/'))
      })
      .catch(() => { dispatch(signOutFailure()) })
  }
}

// Async action for signing in the user from a token saved locally
export const signInFromToken = () => dispatch => {
  // feathersClient.passport.getJWT().then(token => {
  //   if (feathersClient.passport.payloadIsValid(token)) {
  //     feathersClient.passport.verifyJWT(token)
  //       .then(payload => {
  //         // Take name and permission level from payload and save to state.
  //         dispatch(signInSuccess(payload))
  //       })
  //   }
  // })
  feathersClient.reAuthenticate()
    .then(payload => {
      dispatch(signInSuccess(payload.user))
    }).catch(payload => {
      dispatch(signInFailure(payload))
    })
}

