import { connect } from 'react-redux'
import { clearRedirect } from '../actions/global'
import Redirector from '../components/Redirector'

const mapStateToProps = state => {
  return {redirect: state.global.redirect}
}

const mapDispatchToProps = dispatch => {
  return {clearRedirect: () => dispatch(clearRedirect())}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Redirector)
